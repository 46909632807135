import React, { useState } from 'react';

function Terms() {
    const contents = `제 1 장 총칙
제 1 조 (목적)
본 약관은 이조솔루션(이하 "회사"라 합니다)이 온라인으로 제공하는 “드리프트(DRIFT)” 서비스(이하 “서비스”라 합니다) 이용과 관련하여, 회사와 이용자와의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.


제 2 조 (정의)
① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
1. 서비스 : 특허명세서 작성 툴 DRIFT(www.drift-patent.com) 서비스를 말합니다.
2. 유료서비스 : 회사가 제공하는 서비스 중 회원이 회사에 일정 요금을 납부한 후에 이용할 수 있는 서비스를 말합니다.
3. 이용자 : 회사의 사이트에 접속하여 본 약관에 따라 회사가 제공하는 서비스를 이용하는 회원 또는 비회원을 말합니다.
4. 회원 : 본 약관에 따라 회사와 이용계약을 체결하고 이용자 아이디를 부여받은 이용자로서 회사에 개인 정보를 제공하여 회원 등록을 한 이용자를 말합니다.
5. 비회원 : 회원 이외의 이용자를 말합니다.
6. 유료회원 : 유료서비스를 이용하는 회원을 말합니다.
② 이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관련 법령에서 정하는 바에 의하며, 관련 법령에서 정하지 않는 것은 일반적인 상관례에 의합니다.


제 3 조 (약관의 게시와 개정)
① 회사는 이 약관의 내용과 상호, 대표자의 성명, 사업자등록번호, 연락처 등을 이용자가 알 수 있도록 서비스 화면에 게시하거나 기타의 방법으로 이용자에게 공지합니다.
② 회사는 약관의 규제에 관한 법률, 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
③ 회사가 약관을 개정할 경우에는 개정약관의 효력이 발생하기 7 일 이전까지 서비스 사이트에 약관 개정의 내용, 개정약관의 적용 일자에 대하여 공지사항으로 게시하고, 기존회원에게는 약관 개정의 내용과 적용 일자를 이메일로 전송합니다.
④ 회원이 개정약관의 적용에 동의하지 않는 경우 회원은 서비스 이용을 중단하고 본 이용약관에 따른 이용계약을 해지할 수 있습니다. 회원이 개정약관 시행일 이후에도 이용계약을 해지하지 않고 서비스를 이용하는 경우에는, 개정된 본 이용약관에 동의한 것으로 간주됩니다.


제 4 조 (약관의 해석 등)
① 회원이 회사와 개별 계약을 체결하여 서비스를 이용하는 경우 회사는 개별 서비스에 대한 이용약관 또는 운영정책 등(이하 “개별 운영정책 등”)을 둘 수 있으며, 해당 내용이 본 약관과 상충되는 경우 개별 운영정책 등이 우선합니다.
② 이 약관에서 정하지 않은 사항에 관하여는 약관의 규제에 관한 법률, 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률 등의 관계법령 및 전자상거래상의 관행에 따릅니다.


제 5 조 (서비스 이용계약의 성립)
① 서비스 이용계약은 이용자의 서비스 이용계약 내용(본 약관과 회사의 개인정보 처리방침)에 대한 동의 및 회원가입신청에 대한 회사의 이용승낙으로 성립합니다.
② 유료회원의 서비스 이용계약은 제1항 외에 추가로  ‘유료서비스 이용계약’에 대한 이용자의 동의와 회사의 이용승낙으로 성립합니다. 
③ 회사는 회원에 대해 회사정책에 따라 등급별로 구분하여 이용횟수, 서비스 메뉴 등을 세분하여 서비스 이용에 차등을 둘 수 있습니다.


제 6 조 (이용자 정보의 제공)
① 회원으로 가입하여 서비스를 이용하고자 하는 이용자는 이름, 생년월일, 휴대폰 번호, 아이디, 비밀번호, 주소, 이메일, 사업자 정보 등 회사가 요청하는 개인정보를 회원가입양식에 따라 제공하고, 휴대폰 번호 인증을 하여 실명으로 가입하여야 합니다.
② 제1항에서 정한 인증을 거치지 않은 경우 서비스 이용이 제한될 수 있으며, 실명으로 등록하지 않거나 허위정보를 제공한 이용자 또는 회원은 서비스 이용과 관련하여 일체의 권리를 주장할 수 없습니다.
③ 회원은 회원가입시 등록한 제1항의 개인정보가 변경된 경우에는 온라인으로 직접 수정하거나 회사에 이메일 또는 고객센터를 통하여 회사에 고지하여야 하며, 변경 고지를 하지 아니함으로 인하여 발생하는 불이익에 대하여 회사는 책임을 지지 않습니다. 


제 7 조 (개인 정보의 보호 및 관리)
① 회사는 관계 법령이 정하는 바에 따라 계정정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다. 회원의 개인정보 보호 및 사용에 대해서는 관련 법령 및 회사가 별도로 고지하는 개인정보처리방침에 따릅니다.
② 회사는 회원의 귀책사유로 인하여 발생한 회원의 개인정보 노출 또는 도용에 대해서는 일체의 책임을 지지 않습니다.


제 8 조 (회원의 계정 및 비밀번호)
① 회원은 서비스의 원활한 이용, 회원의 정보보호 및 서비스 이용안내 등의 편의를 위하여 이용자가 선정한 아이디를 계정으로 사용합니다. 다만, 회사는 회원의 계정이 반사회적이거나 선량한 미풍양속을 해하는 경우 또는 회사의 운영자로 오인될 우려가 있는 경우 등에는 해당 계정의 사용을 거부하거나 제한할 수 있습니다.
② 회원은 서비스의 이용을 위하여 사용하는 아이디와 비밀번호를 관리할 의무를 부담하며, 아이디와 비밀번호를 사용하여 발생하는 결과에 대한 책임을 집니다. 다만, 회사는 보안 등을 이유로 회원에게 정기적 또는 비정기적으로 비밀번호의 변경을 권고할 수 있습니다.


제 9 조 (서비스의 제공시간 및 중지)
 ① 회사는 특별한 사유가 없는 한 1일 24시간 서비스를 제공합니다. 단, 회사는 서비스의 종류나 성질에 따라 제공하는 서비스 중 일부에 대해서는 별도로 이용시간을 정할 수 있으며, 이 경우 회사는 그 이용시간을 서비스 안내 화면에 고지합니다.
② 회사는 서비스의 제공 및 운영에 필요한 경우 정기/비정기점검을 실시할 수 있고 그 점검시간 동안 서비스가 일시 중지될 수 있습니다. 정기/비정기점검시간은 서비스 제공화면에 공시한 바에 따릅니다.


제 10 조 (서비스 내용 및 변경)
① 회원은 회사가 제공하는 서비스를 이 약관 및 서비스 운영정책, 매뉴얼 등 회사가 정한 규칙(이하, "서비스 이용 규칙")에 따라 이용할 수 있습니다. 
② 회사가 회원에게 제공하는 서비스 내용에 대하여 회사는 제작, 변경, 유지, 보수에 관한 포괄적인 권한을 가집니다.
③ 회사는 새로운 서비스 제공, 각종 버그 패치 등 서비스의 운영상 또는 기술상 필요한 경우, 제공하고 있는 서비스의 전부 또는 일부를 상시적으로 수정, 추가, 폐지 등 변경할 수 있습니다.
④ 회사는 무료로 제공되는 서비스의 전부 또는 일부를 회사의 정책 변경이나 운영상 이유 등 필요에 의하여 수정, 중단 또는 변경할 수 있으며, 이에 대하여 관련 법령상 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.
⑤ 회사는 다음 각호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
1. 전시, 사변, 천재지변, 국가비상사태 기타 이에 준하는 불가항력적인 사유가 있는 경우
2. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
3. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우
4. 기타 회사의 제반 사정으로 서비스를 할 수 없는 경우
⑥ 전항의 경우 외에도 서비스 제공의 제한 및 중지가 회사의 고의 또는 중대한 과실로 인한 경우를 제외하고는 회사는 서비스의 제한 및 중지로 발생하는 문제에 대해서 책임을 부담하지 않습니다.


제 11 조 (권리의 귀속)
①  서비스 및 서비스 내 콘텐츠 등(이하, “회사 콘텐츠 등”)에 대한 저작권을 포함한 지식재산권과 퍼블리시티권 등 일체의 권리는 회사에 귀속됩니다.
② 회원은 회사 콘텐츠 등을 무단 복제, 배포, 변경 등 어떠한 형태로든 사용하거나 제3자로 하여금 사용(이하, “회사 콘텐츠 부정사용”)하도록 할 수 없으며, 회사 콘텐츠 부정사용 시 발생한 손해에 대하여 회사에 배상할 책임을 부담합니다.


제 12 조 (회원의 탈퇴 및 자격상실)
①  회원은 서비스 내 탈퇴 기능을 통해 회원가입 탈퇴 신청을 할 수 있으며, 회사는 관련법령 등에서 정하는 바에 따라 이를 즉시 처리하여야 합니다.
② 다음 각 호에 해당하는 경우를 포함하여 회원의 모든 정보는 탈퇴 이후 바로 삭제되며 복구할 수 없습니다.
1. “내 사건 목록” 2. “완료된 사건 목록


제 2 장 유료서비스 이용
제 13 조 (유료서비스 이용계약)
①  유료서비스는 회원의 유료서비스 이용계약에 대한 동의 및 이용요금의 결제를 수반한 이용신청에 대하여 회사가 이를 승낙한 때(일부 서비스의 경우, 이용 신청 접수 후 사전 공지한 지정된 일자)로부터 개시되며, 회사의 기술적 사유, 운영상 사유 등 기타 사정에 의하여 유료서비스를 즉시 개시할 수 없는 경우에는 제9조에 따라 회원에게 개별 통지 또는 공지합니다.
② 회사는 회원의 유료서비스 이용신청이 있는 경우, 해당 회원에게 이용신청의 내용을 통지합니다.
③ 회사는 월 단위 또는 연 단위로 유료서비스를 제공하되, 서비스 운영상의 필요 기타 제반 여건에 따라 서비스 내용을 추가하거나 변경할 수 있으며 서비스 변경내용에 대하여는 유료회원에게 개별 통지 또는 공지합니다.


제 14 조 (결제수단)
회원은 유료서비스 이용요금 결제를 위하여 계좌이체 방식을 이용할 수 있습니다.


제 15 조 (청약 철회 및 환불)
① 유료회원은 유료서비스 계약 이후 서비스 이용 내역이 없는 경우에 한하여 회사에 청약 철회를 요청할 수 있으며, 환불 금액 정산 방법은 아래와 같습니다.
• 계약일로부터 7일 이내 : 이용요금의 100%
• 계약일로부터 15일 이내 : 이용요금의 80%
• 계약일로부터 60일 이내  : 이용요금의 50%
• 계약일로부터 60일 경과 시에는 환불하지 않습니다.
② 회원이 유료서비스를 이용할 권리를 무상으로 취득하는 등 회원이 직접 비용을 지불하지 아니하고 이용하는 유료서비스에 대하여 회사는 회원에게 유료서비스 결제 대금을 환불할 의무를 부담하지 않습니다.
③ 회원이 이 약관에서 정하는 회원의 의무를 위반하였을 경우, 회사는 서비스 이용계약을 해제, 해지하거나 서비스 이용 제한, 손해배상 청구 등의 조치를 취할 수 있으며, 계약 해지 후 환불하여야 할 금액이 있을 경우에는 일정한 범위 내에서 회원이 부담할 금액을 공제하고 환불합니다. 이 경우 회원은 해당 회사의 조치에 대하여 회사가 정한 절차에 따라 이의 신청을 할 수 있으며, 회사는 정당하다고 확인하는 경우 서비스 이용 재개 등을 할 수 있으며, 회원이 자신의 고의, 과실이 없었음을 입증한 경우 회사는 서비스를 정지한 기간만큼 이용 기간을 연장합니다.
④ 유료회원이 유료서비스의 이용요금을 체납하는 경우 지급기한 경과 후 자동으로 서비스 이용이 해지 및 제한될 수 있으므로, 유료서비스를 유지하고자 하는 유료회원은 이용요금의 체납이 발생하지 않도록 사전에 조치하여야 합니다.
⑤ 회사는 회원으로부터 이용요금 과오납이 발생한 경우 과오금 전액을 환불하여야 합니다. 과오금 환불은 원칙적으로 이용요금 결제와 동일한 방법에 의하되, 동일한 방법으로 환불이 불가능할 경우에는 즉시 이용자에게 고지하고 가능한 환불방법 중 선택할 수 있도록 합니다. 단, 회사가 정당하게 이용요금을 부과하였음을 상세내역 및 자료를 통하여 소명하는 경우에는 환불을 거절할 수 있습니다.
⑥ 회사는 유료서비스 이용기간 개시 후 회원의 서비스 이용내역이 있는 경우에는 이용계약 해제 또는 해지에도 불구하고 환불하지 아니합니다. 단, 이용기간 개시 후 서비스 이용내역이 없는 상태에서의 해제 또는 해지의 경우에는 본조 제1항의 기준에 의하여 환불하며, 서비스 이용내역이 있는 경우라도 회사의 귀책사유에 기한 서비스 제공불능으로 인하여 서비스 이용계약이 적법한 절차를 거쳐 해제 또는 해지되는 경우에는 기이용 일수 및 잔여 계약기간에 비례하여 환불금을 정산합니다.


제 16 조 (유료서비스 내용 변경)
회사는 운영상, 기술상의 필요에 따라 공급하는 유료서비스 내용(이용요금, 제공 서비스 내역 등)을 변경할 수 있으며, 제9조에서 정한 방법에 따라 유료회원에게 사전 공지합니다.


제 3 장 회사와 회원의 의무 및 손해배상 등
제 17 조 (회사의 의무)
① 회사는 본 약관 및 관련법령에서 금지하는 행위 및 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적인 서비스의 제공을 위하여 최선을 다하여 노력합니다.
② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 신용정보를 포함한 일체의 개인정보 보호를 위한 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.
③ 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 회원과의 계약관련 절차 및 내용 등에 있어 회원에게 편의를 제공하도록 노력합니다.


제 18 조 (회원의 의무)
①회원은 회사에서 제공하는 서비스를 본래의 이용 목적 이외의 용도로 사용하거나 다음 각 호에 해당하는 행위를 해서는 안됩니다.
1. 회원가입신청 또는 회원정보 변경시 실명이 아닌 정보 또는 타인의 정보를 사용하거나 허위 사실을 기재하는 행위
2. 타인으로 가장하거나 타인과의 관계를 허위로 명시하는 행위, 타인의 계정 및 비밀번호를 도용, 부정하게 사용하는 행위
3. 알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위
4. 회사로부터 권한을 부여받지 않고 사이트를 변경하거나 사이트에 다른 프로그램을 추가 또는 삽입하거나 서버를 해킹, 역설계, 소스코드의 유출, 스크랩핑 및 변경, 별도의 서버를 구축하거나 웹사이트의 일부분을 임의로 변경 또는 도용하는 행위
5. 회사가 게시한 정보를 임의로 변경하는 행위
6. 불특정 다수를 상대로 회사의 서비스를 제공하여 영리활동을 하는 행위
7. 회사 또는 제3자의 지식재산권을 침해하는 행위
8. 공연히 회사의 명예를 훼손하거나, 업무를 방해하는 행위
9. 고객센터 문의 및 상담시 욕설, 폭언, 성희롱 등의 행위
10. 기타 본 서비스 이용 약관에 반하는 서비스 이용 행위
② 회원은 본 서비스 이용 약관, 회사 홈페이지 상의 공지사항 및 이용약관의 수정사항 등을 확인하고 이를 준수할 의무가 있습니다.
③ 회원의 계정에 관한 관리 책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.
④ 회원이 자신의 아이디, 비밀번호를 도용당하거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 회사에 통보하고 회사의 조치에 협력하여야 합니다.


제 19 조 (계약의 해제 및 해지)
① 회사 또는 회원이 본 약관을 포함한 계약상의 의무를 위반하는 경우 일방은 계약을 위반한 상대방에 대하여 14일 이상의 상당기간을 정하여 서면으로 시정을 최고한 후 최고기간이 경과한 이후에도 시정되지 않는 경우에는 이용계약을 해제 또는 해지할 수 있습니다. 단, 계약위반의 상태가 객관적으로 시정불가능한 경우로서 상대방의 고의 또는 중과실에 의하여 발생하였음이 명백한 경우에는 계약을 즉시 해제 또는 해지할 수 있습니다.
② 제1항의 경우 환불금 정산에 대하여는 제15조를 적용합니다. 
③ 계약위반 사유를 수반하지 않는 이용계약 해지(회원 탈퇴)는 제12조에 정한 바에 따라 자유롭게 할 수 있습니다. 
제 20 조 (손해배상)
① 회사가 제공하는 유료서비스의 하자 등에 의하여 회원에게 손해가 발생한 경우로서 회사의 고의나 과실이 인정되는 경우 회사는 법률이 정한 바에 따라 손해배상의 책임을 집니다.
② 회원이 본 약관 또는 공지된 서비스 이용규칙을 위반하거나 회원이 회사에 요청 또는 지시한 행위를 회사가 수행함으로 인하여 회사에 손해가 발생한 경우 또는 회원이 서비스의 이용과 관련하여 회사에 손해를 입힌 경우 회원은 회사에 대하여 손해배상의 책임을 집니다.
③ 회원이 서비스를 이용함에 있어 행한 불법행위 또는 본 서비스 이용약관을 위반한 행위 또는 회원의 요청 또는 지시에 따라 회사가 행한 행위로 인하여 회사가 제3자로부터 손해배상청구 또는 소송 등 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상할 책임이 있습니다.


제 21 조 (책임의 한계)
① 회사는 천재지변 또는 국가 정책 및 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
② 회사는 회원의 귀책사유로 인한 서비스 중지 또는 이용의 장애, 기간통신사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 회원에게 손해가 발생한 경우에는 책임이 면제됩니다.
③ 회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.
④ 회사는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 한 거래 등에 대하여는 책임을지지 않습니다.
⑤ 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법령에 특별한 규정이 없는 한 책임을 지지 않습니다.


제 22 조 (준거법 및 재판관할)
① 회사와 회원 간 제기된 소송은 대한민국의 법령을 그 준거법으로 합니다.
② 회사와 회원간 발생한 분쟁에 관한 소송의 합의관할은 서울중앙지방법원으로 정합니다.


<부칙>
 (시행일) 이 약관은 2025년 01월 01일부터 적용됩니다.`
    return (
        <div className="terms-main">
        <div className="header-wrap">
                <div className="file-content-header-left">
                    <img src={process.env.PUBLIC_URL + '/drift-logo-black.svg'} alt="로고" className="header-logo"/>
                </div>
                <div className="file-content-header-right">
                    이용약관
                </div>
        </div>
        <div className="terms-contents">
            {contents}
        </div>
        </div>
    );
}

export default Terms;