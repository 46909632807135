import { useContext, useEffect, useState } from 'react';
import Tab from '../components/Tab';
import { useNavigate } from 'react-router-dom';
import FileChanger from './FileChanger';
import DrawingTableForm from './DrawingTableForm';
import {
  getRequestPatentTemp,
  patchRequestPatentDrawings,
  postRequestPatentsDetail,
} from '../api/apis';
import { ModalContext } from '../components/ModalProvider';
import Confirmation from '../components/Confirmation';
import './DrawingSetting.css'
import { CREATE_FILE_KEY } from '../constants/modal.js';

const INVENTION_TYPES = ['기계', '전자', '화학', '바이오'];

const DEFAULT_INVENTION_TYPE = INVENTION_TYPES[0];

const DEFAULT_DRAWING_CONTENT_LENGTH = 4;

const urlToFile = async (url, fileName) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    const file = new File([blob], fileName, { type: blob.type });

    return file;
  } catch (error) {
    console.error('URL로부터 파일을 생성하는 중 오류 발생:', error);
    return null;
  }
};

const createNewContent = () => {
  const contents = Array(DEFAULT_DRAWING_CONTENT_LENGTH)
    .fill(0)
    .map(() => ({
      composition: '',
      sign: '',
    }));

  return [...contents];
};

function DrawingSetting({ fileId }) {
  const navigate = useNavigate();

  const [drawingFiles, setDrawingFiles] = useState([]);
  const [drawingContents, setDrawingContents] = useState([createNewContent()]);

  const { closeModal, openModal } = useContext(ModalContext);

  const [isPending, setIsPending] = useState(true);
  const [savedFormData, setSavedFormData] = useState();
  const [isDone, setIsDone] = useState(false);
  const [selectedItem, setSelectedItem] = useState("files")

  useEffect(() => {
    // TODO: 파일 정보도 불러오기
    getRequestPatentTemp(fileId)
      .then((res) => {
        if (!res) return;
        const INVENTION_TYPES = {"MECHANICAL": "기계", "CHEMICAL": "화학", "ELECTRONICS": "전자", "BIO": "바이오"}
        let INVENTION_TYPE
        if (Object.keys(INVENTION_TYPES).includes(res.invention.type)) {
          INVENTION_TYPE = INVENTION_TYPES[res.invention.type]
        } else {
          INVENTION_TYPE = res.invention.type
        }

        setDrawingFiles([]);
        res.drawingFiles?.forEach((info) => {
          // TODO: 파일 이름 정보 추가
          urlToFile(info.url).then((file) => {
            if (file) {
              const renamedFile = new File([file], info.name, {
                type: file.type,
                lastModified: file.lastModified,
              });
              setDrawingFiles((prev) => [...prev, renamedFile]);
            }
          });
        });

        if (res.drawingContents && res.drawingContents.length > 0) {
          setDrawingContents(res.drawingContents.map((item) => item.contents));
        } else {
          setDrawingContents([createNewContent()]);
        }

        setSavedFormData(structuredClone(res));
      })
      .finally(() => {
        setIsPending(false);
      });
  }, [fileId]);

  const [drawingInfoIndex, setDrawingInfoIndex] = useState(0);

  const getPatentDetail = () => {
    const formData = new FormData();

    drawingFiles.forEach((file, index) => {
      if (file instanceof File) {
        formData.append(`drawingFiles[${index}]`, file, file.name);
      }
    });

    let index = 0;
    drawingContents.forEach((contents, tableIndex) => {
      contents.forEach((content) => {
        formData.append(
          `drawingContents[${index}].drawingIndex`,
          tableIndex + 1
        );

        formData.append(
          `drawingContents[${index}].composition`,
          content.composition || ''
        );

        formData.append(`drawingContents[${index}].sign`, content.sign || '');

        index++;
      });
    });

    return formData;
  };

  const addDrawingContents = () => {
    setDrawingContents((prev) => [...prev, createNewContent()]);
  };

  const handleChangeDrawingContents = (newContents) => {
    setDrawingContents((prev) => {
      const newInfos = prev.map((info, index) => {
        if (index === drawingInfoIndex) return structuredClone(newContents);

        return info;
      });

      return newInfos;
    });
  };

  const handleSave = async () => {

    let request = patchRequestPatentDrawings;

    const formData = getPatentDetail();
    request(fileId, formData)
      .then(() => {
        openModal(
          'SAVE',
          <Confirmation
            title="알림"
            content="사건이 저장되었습니다"
            onConfirm={() => {
              closeModal('SAVE');
              window.location.reload();
            }}
          />,
          () => false
        );
      })
      .catch(() => {
        openModal(
          'SAVE',
          <Confirmation
            title="알림"
            content={'사건이 정상적으로 등록되지 않았습니다'}
            onConfirm={() => {
              closeModal('SAVE');
            }}
          />,
          () => false
        );
      });
  };

  return (
    <div className="drawing-setting-wrap">
        <div className="drawing-setting-header">
            도면 설정
        </div>
        <div className="drawing-setting-main">
            <div className="drawing-setting-nav">
                <div className={`drawing-setting-nav-item ${selectedItem == "files"? "active":""}`} onClick={()=> {setSelectedItem("files")}}>도면 파일</div>
                <div className={`drawing-setting-nav-item ${selectedItem == "descriptions"? "active":""}`} onClick={()=> {setSelectedItem("descriptions")}}>구성 및 도면 부호</div>
            </div>
            {selectedItem == "files" && 
                <div className="drawing-setting-main-wrap">
                    <FileChanger files={drawingFiles} setFiles={setDrawingFiles} />
                </div>
            }
            {selectedItem == "descriptions" && 
                <div className="drawing-setting-main-wrap">
                    <div style={{ display: 'flex' }}>
                        <Tab
                            labels={drawingContents.map((_, index) => `도 ${index + 1}`)}
                            currentIndex={drawingInfoIndex}
                            setIndex={setDrawingInfoIndex}
                        />
                        <button
                            className="add-info-button"
                            onClick={addDrawingContents}
                        >
                            <img
                            alt="add-icon"
                            src="/toolbar/add-file.svg"
                            style={{ width: '16px', height: '16px' }}
                            />
                        </button>
                    </div>
                    <div style={{ height: '10px' }} />

                        <DrawingTableForm
                        contents={drawingContents[drawingInfoIndex] || []}
                        setContents={handleChangeDrawingContents}
                        />

                    </div>
            }
        </div>
        <div className="drawing-setting-footer">
            <div className="drawing-setting-footer-description"></div>
            <div className="drawing-setting-footer-btns">
                <div className="drawing-setting-footer-btn" onClick={() => closeModal(CREATE_FILE_KEY)}>
                    취소
                </div>
                <div className="drawing-setting-footer-btn dsfb-save-btn" onClick={handleSave}>
                    저장하기
                </div>
            </div>
        </div>
    </div>
  );
}

export default DrawingSetting;
