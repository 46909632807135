import './DrawingTableForm.css';

const DrawingTableForm = ({ contents, setContents }) => {
  const handleAddRow = () => {
    setContents([...contents, { composition: '', sign: '' }]);
  };

  const handleRemoveRow = (index) => {
    setContents(contents.filter((_, i) => i !== index));
  };

  const handleChange = (index, field, value) => {
    const newContent = structuredClone(contents);
    newContent[index][field] = value;
    setContents(newContent);
  };

  const handleKeydown = (e, isLast) => {
    console.log(e)
    if (isLast && e.key === 'Enter' ){
      handleAddRow()
    } else if (!isLast && e.key === 'Enter') {
      e.preventDefault();
      const focusableElements = document.querySelectorAll(
        'input'
      );
  
      const currentIndex = Array.prototype.indexOf.call(focusableElements, e.target);
  
      if (currentIndex > -1 && currentIndex < focusableElements.length - 1) {
        const nextElement = focusableElements[currentIndex + 1];
        nextElement.focus();
      }
    }
  }

  console.log('table:', contents);

  return (
    <div className="table-form">
      <div className="table-header">
        <span>도면 부호</span>
        <span>구성</span>
        <span></span>
      </div>
      <div className="table-row-container">
        {contents &&
          contents.map((row, index) => (
            <div className="table-row" key={index}>
              <input
                type="text"
                placeholder="도면 부호를 입력해주세요"
                value={row.sign}
                onChange={(e) => handleChange(index, 'sign', e.target.value)}
              />
              <input
                type="text"
                placeholder="구성을 입력해주세요"
                value={row.composition}
                onChange={(e) =>
                  handleChange(index, 'composition', e.target.value)
                }
                onKeyDown={(e)=>{handleKeydown(e, contents.length -1 == index)}}
              />
              <button
                className="remove-row"
                onClick={() => handleRemoveRow(index)}
              >
                <img alt="close-icon" src="/toolbar/close.svg" />
              </button>
            </div>
          ))}
        <div className="add-row" onClick={handleAddRow}>
          <img
            alt="add-icon"
            src="/toolbar/add-file.svg"
            style={{ width: '16px', height: '16px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default DrawingTableForm;
