import React, {useState, useRef} from 'react';
import './ToolbarHome.css'
import * as UTIF from "utif";

function ToolbarInput({ onCommand }) {
    const fileInputRef = useRef(null); // 숨겨진 파일 입력 요소를 참조하기 위한 ref

    const handleUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const arrayBuffer = event.target.result;
                const isTIFF = file.type === "image/tiff" || file.name.endsWith(".tiff");
    
                if (isTIFF) {
                    // TIFF 이미지 처리
                    const tiffImages = UTIF.decode(arrayBuffer); // TIFF 이미지 디코딩
    
                    // 첫 번째 페이지만 처리
                    const tiffImage = tiffImages[0];
                    UTIF.decodeImage(arrayBuffer, tiffImage);
                    const rgba = UTIF.toRGBA8(tiffImage); // RGBA 데이터로 변환
    
                    // Canvas를 사용해 이미지 그리기
                    const tiffCanvas = document.createElement("canvas");
                    const tiffCtx = tiffCanvas.getContext("2d");
                    tiffCanvas.width = tiffImage.width;
                    tiffCanvas.height = tiffImage.height;
    
                    const imageData = tiffCtx.createImageData(
                        tiffImage.width,
                        tiffImage.height
                    );
                    imageData.data.set(rgba); // RGBA 데이터 설정
                    tiffCtx.putImageData(imageData, 0, 0);
    
                    // 크기 제한 및 변환
                    const maxWidth = 600;
                    const scale = Math.min(maxWidth / tiffCanvas.width, 1);
                    const scaledCanvas = document.createElement("canvas");
                    const scaledCtx = scaledCanvas.getContext("2d");
                    scaledCanvas.width = tiffCanvas.width * scale;
                    scaledCanvas.height = tiffCanvas.height * scale;
    
                    scaledCtx.drawImage(
                        tiffCanvas,
                        0,
                        0,
                        scaledCanvas.width,
                        scaledCanvas.height
                    );
    
                    const result = scaledCanvas.toDataURL("image/jpeg", 0.8); // JPEG 변환
                    onCommand("insertImage", result);
                } else {
                    // 일반 이미지 처리
                    const img = new Image();
                    img.onload = () => {
                        const canvas = document.createElement("canvas");
                        const ctx = canvas.getContext("2d");
    
                        const maxWidth = 600;
                        const scale = Math.min(maxWidth / img.width, 1);
                        canvas.width = img.width * scale;
                        canvas.height = img.height * scale;
    
                        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                        const result = canvas.toDataURL("image/jpeg", 0.8);
                        onCommand("insertImage", result);
                    };
                    img.src = event.target.result;
                }
            };
    
            reader.readAsArrayBuffer(file); // TIFF 지원을 위해 ArrayBuffer로 읽음
        }
    
        // 파일 선택 후 input 값을 리셋
        event.target.value = null;
    };

    return (
        <div className="toolbar">
            <div className="toolbar-edit-icons">
                <div className="toolbar-edit-icons-column">
                    <div className="toolbar-icon icon"
                    onClick={(e) => { 
                        e.preventDefault(); 
                        onCommand('cut');
                    }}>
                        <img src="/toolbar/잘라내기.svg" />
                    </div>
                    <div className="toolbar-icon icon" onClick={(e) => { 
                        e.preventDefault(); 
                        onCommand('copy');
                    }}>
                        <img src="/toolbar/복사.svg" />
                    </div>
                    <div className="toolbar-icon icon">
                        <img src="/toolbar/서식복사.svg" />
                    </div>
                </div>
                <div className="toolbar-edit-icons-column">
                    <div className="toolbar-icon icon" onClick={(e) => { 
                        e.preventDefault(); 
                        onCommand('undo');
                    }}>
                        <img src="/toolbar/undo.svg" />
                    </div>
                    <div className="toolbar-icon icon" onClick={(e) => { 
                        e.preventDefault(); 
                        onCommand('redo');
                    }}>
                        <img src="/toolbar/redo.svg" />
                    </div>
                    {/* <div className="toolbar-icon icon">
                        <img src="/toolbar/업로드.svg" />
                    </div> */}
                </div>
            </div>
            <div className="toolbar-divider"></div>
            <div className="toolbar-add-identifiers">
                <div className="add-identifier icon">
                    <div className="select-identifier">
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '')}}>【】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '도')}}>【도】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '표')}}>【표】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '청구항')}}>【청구항】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '수학식')}}>【수학식】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '화학식')}}>【화학식】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '반응식')}}>【반응식】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '실시예')}}>【실시예】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '특허문헌')}}>【특허문헌】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '수탁기관')}}>【수탁기관】</div>
                    </div>
                    <div className="identifier-icon icon">
                        <img src="/toolbar/식별항목.svg" />
                    </div>
                    <div className="identifier-title">식별항목 추가</div>
                </div>
                <div className="add-identifier icon">
                    <div className="select-identifier">
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '발명의 설명')}}>【발명의 설명】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '발명의 명칭')}}>【발명의 명칭】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '기술분야')}}>【기술분야】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '발명의 배경이 되는 기술')}}>【발명의 배경이 되는 기술】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '해결하고자 하는 과제')}}>【해결하고자 하는 과제】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '과제의 해결 수단')}}>【과제의 해결 수단】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '발명의 효과')}}>【발명의 효과】</div>
                    </div>
                    <div className="identifier-icon icon">
                        <img src="/toolbar/표준식별항목.svg" />
                    </div>
                    <div className="identifier-title">표준식별항목 추가</div>
                </div>
            </div>
            <div className="toolbar-divider"></div>
            <div className="toolbar-download" onClick={handleUpload}>
                <div className="toolbar-image-icon big-icon">
                    <img src="/toolbar/image-icon.svg" />
                </div>
                <div className="toolbar-paste-title">이미지</div>
                <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                />
            </div>
        </div>
    );
}

export default ToolbarInput;