export const logOut = () => {
  sessionStorage.setItem('SessionId', null);
  window.location.reload();
};

export const login = (username, authorization) => {
  sessionStorage.setItem('userID', username);
  sessionStorage.setItem('SessionId', authorization);
  window.location.reload();
};
