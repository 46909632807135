import { useState, useRef, useEffect } from 'react';
import './DropDown.css';

const Dropdown = ({ labels, handlers }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = (event) => {
    event.stopPropagation();

    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    event.stopPropagation();

    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="dropdown-menu-container" ref={dropdownRef}>
      <div className="patent-action" onClick={toggleDropdown}>
        <img alt="more" src="/dashboard/more.svg"></img>
      </div>

      {isOpen && labels.length > 0 && (
        <ul className="dropdown-menu">
          {labels.map((label, index) => (
            <li
              key={label}
              className="dropdown-item"
              onClick={(event) => {
                event.stopPropagation();
                handlers[index]();
                setIsOpen(false);
              }}
            >
              {label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
