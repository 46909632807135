import React, { useState, useEffect, useMemo } from 'react';
import './FilesContent.css';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import File from './File.js';
import { getRequestDonePatents } from '../api/apis.js';
import DashboardHeader from './DashboardHeader.js'

function DoneList() {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const userID = sessionStorage.getItem('userID');

  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(0); // 현재 페이지 상태
  const [totalPages, setTotalPage] = useState(0); // 현재 페이지 상태

  const handlePageChange = (page) => {
    if (page >= 1 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    getRequestDonePatents(currentPage)
      .then((data) => {
        const patentList = data.content.map((patent) => {
          const {
            id,
            patentId,
            title,
            client,
            dueDate,
            receiptDate,
            inventionType,
            memo,
            status,
          } = patent;

          return {
            id,
            patentId,
            title,
            client,
            dueDate,
            receiptDate,
            inventionType,
            memo,
            status,
          };
        });

        setTotalPage(data.totalPages);
        setFileList(patentList);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage]);

  return (
    <>
      {/* {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 1000,
            flexDirection: 'column',
          }}
        >
          <CircularProgress />
          <div className="wait">Loading</div>
        </Box>
      )} */}
      <DashboardHeader />
      <div className="dashboard-main-wrap">
        <div className="patent-list-title-wrap">
          <div className="patent-list-title">완료된 사건 목록</div>
          <div className="delete-notification">
            완료된 사건은 30일간 보관 후 삭제됩니다.
          </div>
        </div>
        <div className="patent-list-wrap">
          <div className="patent-list-header">
            <div className="patent-id">사건번호</div>
            <div className="patent-title">사건명</div>
            <div className="patent-client">클라이언트</div>
            <div className="patent-start">수임일</div>
            <div className="patent-due">초안 송부 기한</div>
            <div className="patent-status">상태</div>
            <div className="patent-action">
              <img src="/dashboard/sort.svg"></img>
            </div>
          </div>
          {fileList.map(
            ({ id, patentId, title, client, receiptDate, dueDate, status }, index) => {
              return (
                <File
                  key={id}
                  id={id}
                  patentId={patentId}
                  title={title}
                  client={client}
                  start={receiptDate}
                  due={dueDate}
                  status={status}
                  isDonelist={true}
                />
              );
            }
          )}
          {fileList.length == 0 && <div className="no-files">완료된 사건이 없습니다.</div>}
        </div>
        <div className="pagination-wrap">
          <div
            className="pagination-btn"
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          >
            <img src="/dashboard/go-first-page.svg"></img>
          </div>
          <div
            className="pagination-btn"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <img src="/dashboard/prev-page.svg"></img>
          </div>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`pagination-btn ${
                currentPage === index + 1 ? 'active' : ''
              }`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <div
            className="pagination-btn"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <img src="/dashboard/next-page.svg"></img>
          </div>
          <div
            className="pagination-btn"
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          >
            <img src="/dashboard/go-last-page.svg"></img>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoneList;
