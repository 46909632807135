import { useLocation, useNavigate } from 'react-router-dom';
import './File.css';
import Dropdown from '../components/DropDown';
import { deleteRequestPatents, postRequestPatentComplete } from '../api/apis';
import { useContext } from 'react';
import { ModalContext } from '../components/ModalProvider';
import Confirmation from '../components/Confirmation';
import { getPatentDetail } from '../api/apis.js';
import { saveAs } from 'file-saver';
import { AlignmentType, Document, Packer, Paragraph, TextRun, ImageRun, PageBreak } from 'docx';
import ModifyFile from '../ModifyFile.js';
import { CREATE_FILE_KEY } from '../constants/modal.js';
import {
  CLOSE_DIALOG_CREATE_FILE_KEY,
} from '../constants/modal.js';
import Dialog from '../components/Dialog.js';

function File({ id, patentId, title, client, start, due, memo, status, isDonelist=false }) {
  const navigate = useNavigate();
  const location = useLocation();
  const fileInfo = {id, patentId, title, client, start, due, memo}

  const { openModal, closeModal } = useContext(ModalContext);

  const editPatent = () => {
    const params = new URLSearchParams(location.search);
    const currentMenu = params.get('menu');

    const newParams = new URLSearchParams({
      menu: currentMenu,
      fileId: id,
      patentId,
      title,
    });

    navigate(`/?${newParams.toString()}`);
  };

  const applySenitize = (text) => {
    text = text.replace(/&lt;b&gt;(.*?)&lt;\/b&gt;/g, '$1');
    text = text.replace(/&lt;i&gt;(.*?)&lt;\/i&gt;/g, '$1');
    text = text.replace(/&lt;u&gt;(.*?)&lt;\/u&gt;/g, '$1');
    text = text.replace(/&lt;strike&gt;(.*?)&lt;\/strike&gt;/g, '$1');
    text = text.replace(/&lt;sup&gt;(.*?)&lt;\/sup&gt;/g, '$1');
    text = text.replace(/&lt;sub&gt;(.*?)&lt;\/sub&gt;/g, '$1');
    text = text.replace(/!!/g, '!\n!');
    text = text.replace(/\?\?(.*?)\?\?/g, '');
    text = text.replace(/'''(.*?)'''\s*/g, '');
    text = text.replace(/##(.*?)##/g, '');
    text = text.replace(/\^\^(.*?)\^\^/g, "");
    text = text.replace(/\/\/\/(.*?)\/\/\//g, '$1');
    text = text.replace(/!【(.*?)】!/g, '【$1】');
    return text;
  };

  const downloadWord = async () => {
    let content
    let filename
    let client
    let patentId
    let drawings
    await getPatentDetail(id)
        .then((data) => {
            content = applySenitize(data.aiContent)
            filename = data.title
            client = data.client
            patentId = data.patentId
            drawings = data.drawingFiles
        })
        .catch((err) => {
            console.error(err);
            alert("존재하지 않는 사건번호입니다.")
        })
        .finally(() => {
        });

    const lines = content.split('\n');
    const paragraphs = [];

    // 각 줄을 순회하며 페이지 나누기 처리 및 이미지 삽입
    for (const line of lines) {
      if (line.trim().startsWith("@@@")) {
        paragraphs.push(new Paragraph({ children: [new PageBreak()] }));
      } else if (/^(【발명의 설명】|【청구범위】|【요약서】|【도면】)$/.test(line.trim())) {
        const lineChildren = [];
        lineChildren.push(
            new TextRun({
                text: line,
                font: '바탕체',
                size: 24, // 12pt
            })
        );
        paragraphs.push(
            new Paragraph({
                children: lineChildren,
                alignment: AlignmentType.CENTER,
                spacing: { line: 500 },
            })
        );
      } else if (line.trim().startsWith("!*") && line.trim().endsWith("*!")) {
        const lineChildren = [];
        const imageRegex = /!\*(.*?)\*!/g;
        const match = imageRegex.exec(line);
        if (match) {
            try {
                const { blob, width, height } = await fetchImageBlobWithDimensions(match[1]);
                const maxWidth = 600;
                let adjustedWidth = width;
                let adjustedHeight = height;

                if (width > maxWidth) {
                    const scale = maxWidth / width;
                    adjustedWidth = maxWidth;
                    adjustedHeight = Math.round(height * scale); // 비율 유지
                }
                lineChildren.push(
                    new ImageRun({
                        data: blob,
                        transformation: {
                            width: adjustedWidth, // 조정된 너비
                            height: adjustedHeight, // 조정된 높이
                        },
                    })
                );
            } catch (error) {
                console.error(`Error fetching image: ${error}`);
            }
        }

        paragraphs.push(
            new Paragraph({
                children: lineChildren,
                alignment: AlignmentType.JUSTIFIED,
                spacing: { line: 500 },
            })
        );
    } else {
          const lineChildren = [];
          const textWithoutImage = line.replace(/\*\*(\d+)\*\*/, ""); // 이미지 태그 제거

          // 텍스트 처리
          if (textWithoutImage.trim()) {
              lineChildren.push(
                  new TextRun({
                      text: textWithoutImage,
                      font: '바탕체',
                      size: 24, // 12pt
                  })
              );
          }

          // 이미지 처리
          const imageRegex = /\*\*(\d+)\*\*/g;
          const match = imageRegex.exec(line);
          if (match) {
              const index = parseInt(match[1], 10);
              try {
                const { blob, width, height } = await fetchImageBlobWithDimensions(drawings[index].url);
                const maxWidth = 600;
                let adjustedWidth = width;
                let adjustedHeight = height;

                if (width > maxWidth) {
                    const scale = maxWidth / width;
                    adjustedWidth = maxWidth;
                    adjustedHeight = Math.round(height * scale); // 비율 유지
                }
                lineChildren.push(
                    new ImageRun({
                        data: blob,
                        transformation: {
                            width: adjustedWidth, // 조정된 너비
                            height: adjustedHeight, // 조정된 높이
                        },
                    })
                );
              } catch (error) {
                  console.error(`Error fetching image: ${error}`);
              }
          }

          paragraphs.push(
              new Paragraph({
                  children: lineChildren,
                  alignment: AlignmentType.JUSTIFIED,
                  spacing: { line: 500 },
                  indent: { firstLine: 903 },
              })
          );
      }
    }

    const doc = new Document({
        sections: [
            {
                properties: {},
                children: paragraphs,
            },
        ],
    });

    Packer.toBlob(doc).then((blob) => {
        saveAs(blob, `${patentId}_${filename}_${client}.docx`); // 파일 저장
    });
  };

  const fetchImageBlobWithDimensions = async (url) => {
      const response = await fetch(url);
      if (!response.ok) {
          throw new Error(`Failed to fetch image from ${url}`);
      }
      const blob = await response.blob();
      const img = new Image();
  
      return new Promise((resolve, reject) => {
          img.onload = () => {
              resolve({ blob, width: img.width, height: img.height });
              URL.revokeObjectURL(img.src); // URL 해제
          };
          img.onerror = reject;
          img.src = URL.createObjectURL(blob); // Blob을 사용해 이미지 객체 생성
      });
  };


  const editContent = () => {
    navigate(`/contentEditor?id=${id}`);
  };

  const modifyContent = () => {
    openModal(CREATE_FILE_KEY, <ModifyFile id={id} fileInfo={fileInfo} />, () => false)
  };

  const deletePatent = () => {
    openModal(
      CLOSE_DIALOG_CREATE_FILE_KEY,
      <Dialog
        title="알림"
        content={`삭제된 사건은 복구할 수 없습니다 \n ${title} 사건을 정말 삭제하시겠습니까?`}
        confirmButtonText="삭제하기"
        onCancel={() => closeModal('KEY')}
        onConfirm={() => {deleteRequestPatents(id).then(() => window.location.reload());}}
      />
    );
  };

  const setDone = () => {
    postRequestPatentComplete(id).then(() => window.location.reload());
  };

  const handleClick = () => {
    if (status === 'WAITING_INPUT') {
      editPatent();
    } else if (status === 'EDITABLE') {
      editContent();
    } else if (status === 'GENERATING') {
      openModal(
        'GENERATING',
        <Confirmation
          title="알림"
          content="명세서 초안을 생성 중입니다. 잠시만 기다려주십시오."
          onConfirm={() => {
            closeModal('GENERATING');
          }}
        />
      );
    }
  };

  let labels
  let handlers

  if (status === 'WAITING_INPUT') {
    labels = ['사건 정보 수정', '사건 삭제하기']
    handlers = [modifyContent, deletePatent]
  } else if (status === 'EDITABLE' && isDonelist == false) {
    labels = ['사건 정보 수정', '사건 내보내기', '사건 삭제하기', '완료 처리하기']
    handlers = [modifyContent, downloadWord, deletePatent, setDone]
  } else if (status === 'EDITABLE' && isDonelist == true) {
    labels = ['사건 정보 수정', '사건 내보내기', '사건 삭제하기']
    handlers = [modifyContent, downloadWord, deletePatent, setDone]
  } else if (status === 'GENERATING') {
    labels = ['사건 정보 수정', '사건 삭제하기']
    handlers = [modifyContent, deletePatent]
  }

  return (
    <div className="patent-info" onClick={handleClick}>
      <div className="patent-id">{patentId}</div>
      <div className="patent-title">{title}</div>
      <div className="patent-client">{client}</div>
      <div className="patent-start">{start}</div>
      <div className="patent-due">{due}</div>
      <div className="patent-status">
        {status === 'WAITING_INPUT' ? (
          <div className="patent-status-info waiting-input">미등록</div>
        ) : status === 'GENERATING' ? (
          <div className="patent-status-info on-processing">생성중</div>
        ) : (
          <div className="patent-status-info can-edit">편집 가능</div>
        )}
      </div>
      <Dropdown labels={labels} handlers={handlers} />
    </div>
  );
}

export default File;
