import React, { useState, useEffect, useContext, useRef } from 'react';
import { useBoilerplateStore } from '../store.js';
import './FilesContent.css'
import ContentEditable from 'react-contenteditable';
import { getUserInfo } from '../api/apis.js';
import { ModalContext } from '../components/ModalProvider.js';
import { CREATE_FILE_KEY } from '../constants/modal.js';
import ChangeEmail from './ChangeEmail.js'
import ChangePassword from './ChangePassword.js'
import ChangePlan from './ChangePlan.js'
import CancelPlan from './CancelPlan.js'
import DashboardHeader from './DashboardHeader.js'

const ContentEditableWithFocus = ({ tagName, html, onChange, onBlur, disabled, className }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (!disabled && ref.current) {
            ref.current.focus(); // 포커스를 설정
            
            const range = document.createRange();
            const selection = window.getSelection();
            range.selectNodeContents(ref.current); // 요소의 콘텐츠 전체 선택
            range.collapse(false); // 끝으로 범위를 축소 (커서 맨 뒤로 이동)
            selection.removeAllRanges(); // 기존 선택 영역 제거
            selection.addRange(range); // 새로운 범위 추가
        }
    }, [disabled]);

    return (
        <ContentEditable
            innerRef={ref} // ref 연결
            tagName={tagName}
            html={html}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            className={className}
        />
    );
};

const ContextMenu = ({ x, y, type, position, onAddFolder, onAddText, onChangeContent, onRemove }) => {
    if(type == "folder") {
        return(
            <div
                className="context-menu-nav"
                style={{ top: y, left: x }}
            >
                <div className="context-menu-item" onClick={onChangeContent}>
                폴더 수정
                </div>
                {position.length < 3 &&
                <div className="context-menu-item" onClick={onAddFolder}>
                폴더 추가
                </div>}
                <div className="context-menu-item" onClick={onRemove}>
                폴더 삭제
                </div>
                <div className="context-menu-item" onClick={onAddText}>
                항목 추가
                </div>
            </div>
        )
    }
    if(type == "text") {
        return(
            <div
                className="context-menu-nav"
                style={{ top: y, left: x }}
            >
                <div className="context-menu-item" onClick={onChangeContent}>
                항목 수정
                </div>
                <div className="context-menu-item" onClick={onRemove}>
                항목 삭제
                </div>
            </div>
        )
    }
    return (
      <div
        className="context-menu-nav"
        style={{ top: y, left: x }}
      >
        {position.length < 3 &&
        <div className="context-menu-item" onClick={onAddFolder}>
          폴더 추가
        </div>}
        <div className="context-menu-item" onClick={onAddText}>
          항목 추가
        </div>
      </div>
    );
};

function MyPage() {
    const [selectedInfo, setSelectedInfo] = useState("MyInfo");
    const tempUserInfo = {
        "id": 0,
        "profile": {
          "name": "string",
          "loginId": "string",
          "email": "string"
        },
        "subscription": {
          "id": 0,
          "type": "string",
          "startDate": "2024-12-31",
          "endDate": "2024-12-31"
        },
        "isUpgradeInProgress": true
    }
    const [userInfo, setUserInfo] = useState(tempUserInfo);
    const {boilerplates, setBoilerplates, removeBoilerplate} = useBoilerplateStore();
    const [selectedFolder, setSelectedFolder] = useState([]);
    const { openModal } = useContext(ModalContext);
    const [editablePath, setEditablePath] = React.useState(null); // 현재 편집 가능한 경로
    
    useEffect(() => {
        getUserInfo()
        .then((data) => {
            const formatDate = (isoDate) => {
                const dateObj = new Date(isoDate);
                return `${dateObj.getFullYear()}. ${dateObj.getMonth() + 1}. ${dateObj.getDate()}.`;
            };

            const calculateNextBillingDate = (isoDate) => {
                const dateObj = new Date(isoDate);
                dateObj.setDate(dateObj.getDate() + 1); // 하루 추가
                return formatDate(dateObj);
            };
        
            // 날짜 형식 변경
            const formattedData = {
                ...data,
                subscription: {
                  ...data.subscription,
                  startDate: formatDate(data.subscription.startDate),
                  endDate: formatDate(data.subscription.endDate),
                  nextBillingDate: calculateNextBillingDate(data.subscription.endDate),
                },
            };

            setUserInfo(formattedData);
        })
        .catch((err) => {
            console.error(err);
        })
        .finally(() => {
        });
    }, []);

    const selectFolder = (position, index) => {
        setSelectedFolder((prevSelected) => {
            const updatedFolders = [...prevSelected];

            if (updatedFolders[position] == index) {
                return prevSelected;
            }

            while (updatedFolders.length > position) {
                updatedFolders.pop();
            }

            updatedFolders[position] = index;

            return updatedFolders;
        });
    };
    const [contextMenu, setContextMenu] = useState(null);

    const handleContextMenu = (event, position, type) => {
        event.preventDefault();
        setContextMenu({
            position,
            type,
            x: event.clientX,
            y: event.clientY,
        });
    };

    const handleAddFolder = () => {
        if (!contextMenu) return;

        console.log(contextMenu.position)

        if (contextMenu.position.length == 3) {
            alert("더이상 폴더를 추가할 수 없습니다.")
            return
        }
    
        const updatedBoilerplates = [...boilerplates];
        let target = updatedBoilerplates;
    
        // Navigate to the correct level
        contextMenu.position.forEach((pos) => {
          target = target[pos].contents;
        });
    
        // Add new folder
        target.push({
          type: "folder",
          name: "새 폴더",
          contents: [],
        });
    
        setBoilerplates(updatedBoilerplates);
        setContextMenu(null);
    };

    const handleAddText = () => {
        if (!contextMenu) return;
    
        const updatedBoilerplates = [...boilerplates];
        let target = updatedBoilerplates;
    
        // Navigate to the correct level
        contextMenu.position.forEach((pos) => {
          target = target[pos].contents;
        });
    
        // Add new text item
        target.push({
          type: "text",
          name: "새 텍스트",
          text: "여기에 내용을 입력하세요.",
        });
    
        setBoilerplates(updatedBoilerplates);
        setContextMenu(null);
    };

    const handleRemoveItem = () => {
        if (!contextMenu) return;
        const confirmDelete = window.confirm("정말로 해당 항목을 삭제하시겠습니까?");
        if (confirmDelete) {
            removeBoilerplate(contextMenu.position);
            setSelectedFolder(selectedFolder.slice(0,contextMenu.position.length-1))
            setContextMenu(null);
        }
    }
    
    const handleChangeContent = () => {
        if (!contextMenu) return;

        setEditablePath(contextMenu.position);
        setContextMenu(null);
    };

    return (
        <>
        <DashboardHeader />
        <div className="dashboard-main-wrap">
            <div className="patent-list-title-wrap">
                <div className="patent-list-title">마이페이지</div>
            </div>
            <div className="info-selection-wrap">
                <div className={`select-info ${selectedInfo == "MyInfo" && "active"}`} onClick={()=>setSelectedInfo("MyInfo")}>내 정보</div>
                <div className={`select-info ${selectedInfo == "BoilerPlate" && "active"}`} onClick={()=>setSelectedInfo("BoilerPlate")}>보일러플레이트</div>
            </div>
            {selectedInfo == "MyInfo" &&
            <div className="my-info-wrap">
                <div className="my-profile-wrap">
                    <div className="my-profile-title">
                        내 프로필
                    </div>
                    <div className="my-profile-card">
                        <div className="my-profile-card-line">
                            <div className="my-profile-card-label">
                                이름
                            </div>
                            <div className="my-profile-card-info">
                                {userInfo.profile.name}
                            </div>
                        </div>
                        <div className="my-profile-card-line">
                            <div className="my-profile-card-label">
                                ID
                            </div>
                            <div className="my-profile-card-info">
                                {userInfo.profile.loginId}
                            </div>
                        </div>
                        <div className="my-profile-card-line">
                            <div className="my-profile-card-label">
                                E-mail
                            </div>
                            <div className="my-profile-card-info">
                                {userInfo.profile.email}
                                <div className="change-btn" onClick={() => openModal(CREATE_FILE_KEY, <ChangeEmail currentEmail={userInfo.profile.email} />, () => false)}>변경하기</div>
                            </div>
                        </div>
                        <div className="my-profile-card-line">
                            <div className="my-profile-card-label">
                                비밀번호
                            </div>
                            <div className="my-profile-card-info">
                                <div className="change-btn" onClick={() => openModal(CREATE_FILE_KEY, <ChangePassword />, () => false)}>변경하기</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="plan-info-wrap">
                    <div className="plan-info-title">
                        구독 정보
                    </div>
                    <div className="plan-card-list">
                        {userInfo.subscription.type == "FREE_TRIAL" &&
                        <div className={`plan-card active`}>
                            <div className="plan-card-title">
                                무료 체험
                            </div>
                            <div className="plan-card-subtitle">
                                Pro 플랜 기능을 7일간 무료로 사용할 수 있어요.
                            </div>
                            <div className="plan-card-function-wrap">
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">등록 가능 사건 수 <b>무제한</b></div>
                                </div>
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">명세서 생성 횟수 <b>무제한</b></div>
                                </div>
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">다운로드 가능 기한 <b>무제한</b></div>
                                </div>
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">업로드 가능 도면 수 <b>무제한</b></div>
                                </div>
                            </div>
                            <div className="plan-card-action-btn">
                                {userInfo.subscription.type == "FREE_TRIAL" ? "현재 플랜": "선택 불가"}
                            </div>
                        </div>}
                        <div className={`plan-card ${userInfo.subscription.type == "BASIC_PLAN" && "active"}`}>
                            <div className="plan-card-title">
                                Basic 플랜
                            </div>
                            <div className="plan-card-subtitle">
                                가볍게 사용하시는 분들에게 좋아요. 
                            </div>
                            <div className="plan-card-function-wrap">
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">등록 가능 사건 수 <b>30건</b></div>
                                </div>
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">명세서 생성 횟수 <b>3건/month</b></div>
                                </div>
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">다운로드가능 기한 <b>30일</b></div>
                                </div>
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">업로드 가능 도면 수 <b>5장</b></div>
                                </div>
                            </div>
                            <div className="plan-card-action-btn" onClick={userInfo.subscription.type !== "BASIC_PLAN" ? ()=>{openModal(CREATE_FILE_KEY, <ChangePlan plan={"BASIC_PLAN"} />, () => false)}: ()=>{}}>
                                {userInfo.subscription.type == "BASIC_PLAN" ? "현재 플랜": userInfo.subscription.type == "PRO_PLAN"? "플랜 변경": "플랜 업그레이드"}
                            </div>
                        </div>
                        <div className={`plan-card ${userInfo.subscription.type == "PRO_PLAN" && "active"}`}>
                            <div className="plan-card-title">
                                Pro 플랜
                            </div>
                            <div className="plan-card-subtitle">
                            DRIFT를 무제한으로 활용하기 좋아요.
                            </div>
                            <div className="plan-card-function-wrap">
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">등록 가능 사건 수 <b>무제한</b></div>
                                </div>
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">명세서 생성 횟수 <b>무제한</b></div>
                                </div>
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">다운로드 가능 기한 <b>무제한</b></div>
                                </div>
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">업로드 가능 도면 수 <b>무제한</b></div>
                                </div>
                            </div>
                            <div className="plan-card-action-btn" onClick={userInfo.subscription.type !== "PRO_PLAN" ? ()=>{openModal(CREATE_FILE_KEY, <ChangePlan plan={"PRO_PLAN"} />, () => false)}: ()=>{}}>
                                {userInfo.subscription.type == "PRO_PLAN" ? "현재 플랜": "플랜 업그레이드"}
                            </div>
                        </div>
                    </div>
                    <div className="user-plan-info-wrap">
                        <div className="user-plan-info-line">
                            <div className="user-plan-info-label">구독 기간</div>
                            <div className="user-plan-info">{userInfo.subscription.startDate} ~ {userInfo.subscription.endDate}</div>
                        </div>
                        <div className="user-plan-info-line">
                            <div className="user-plan-info-label">다음 청구일</div>
                            <div className="user-plan-info">{userInfo.subscription.nextBillingDate}</div>
                        </div>
                        <div className="user-plan-info-line">
                            <div className="user-plan-info-label">구독 해지</div>
                            <div className="user-plan-info" onClick={userInfo.subscription.type == "FREE_TRIAL"? () => {alert("무료 체험은 해지할 수 없습니다.")} : () => openModal(CREATE_FILE_KEY, <CancelPlan id={userInfo.id} />, () => false)}>
                                {userInfo.subscription.isCancellationInProgress ? <div className="unsubscribe-going">문의 진행 중</div> : <div className="unsubscribe-btn">해지 문의</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {selectedInfo == "BoilerPlate" &&
            <div className="boilerplate-wrap">
                <div className="boilerplate-list" onClick={() => {setContextMenu(null)}}>
                    <div className="boilerplate-section" onContextMenu={(event) => handleContextMenu(event, selectedFolder.slice(0, 0))}>
                        {boilerplates.map((boilerplate, index) => {
                            const currentPath = [...selectedFolder.slice(0, 0), index]; // 현재 항목의 경로
                            const isEditable = JSON.stringify(editablePath) === JSON.stringify(currentPath)
                            const isActive = JSON.stringify(selectedFolder.slice(0, 1)) === JSON.stringify(currentPath)
                            if(boilerplate.type == "folder"){
                                return (
                                <div key={index} className={`boilerplate-item ${isActive? "active":""}`} onContextMenu={(e) => {e.stopPropagation(); handleContextMenu(e, [...selectedFolder.slice(0, 0), index], boilerplate.type)}} onClick={()=> {selectFolder(0, index)}}><img className="boilerplate-icon" src="/dashboard/folder-icon.svg" /> 
                                    <ContentEditableWithFocus
                                        tagName="span"
                                        html={boilerplate.name}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[index].name = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                        onBlur={() => setEditablePath(null)}
                                        disabled={!isEditable}
                                    />
                                </div>
                            )
                            } else {
                                return (
                                <div key={index} className="boilerplate-item" onContextMenu={(e) => {e.stopPropagation(); handleContextMenu(e, [...selectedFolder.slice(0, 0), index], boilerplate.type)}}><img className="boilerplate-icon" src="/dashboard/text-icon.svg" /> 
                                    <ContentEditableWithFocus
                                        tagName="span"
                                        className="focus-ellipsis"
                                        html={boilerplate.text}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[index].text = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                        onBlur={() => setEditablePath(null)}
                                        disabled={!isEditable}
                                    />
                                </div>
                                )
                            }
                        })}
                    </div>
                    <div className="boilerplate-section" onContextMenu={(event) => handleContextMenu(event, selectedFolder.slice(0, 1))}>
                        {boilerplates[selectedFolder[0]]?.contents?.map((boilerplate, index) => {
                            const currentPath = [...selectedFolder.slice(0, 1), index]; // 현재 항목의 경로
                            const isEditable = JSON.stringify(editablePath) === JSON.stringify(currentPath)
                            const isActive = JSON.stringify(selectedFolder.slice(0, 2)) === JSON.stringify(currentPath)
                            if(boilerplate.type == "folder"){
                                return (
                                <div key={index} className={`boilerplate-item ${isActive? "active":""}`} onContextMenu={(e) => {e.stopPropagation(); handleContextMenu(e, [...selectedFolder.slice(0, 1), index], boilerplate.type)}} onClick={()=> {selectFolder(1, index)}}><img className="boilerplate-icon" src="/dashboard/folder-icon.svg" />
                                    <ContentEditableWithFocus
                                        tagName="span"
                                        html={boilerplate.name}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[selectedFolder[0]].contents[index].name = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                        onBlur={() => setEditablePath(null)}
                                        disabled={!isEditable}
                                    />
                                </div>
                            )
                            } else {
                                return (
                                <div key={index} className="boilerplate-item" onContextMenu={(e) => {e.stopPropagation(); handleContextMenu(e, [...selectedFolder.slice(0, 1), index], boilerplate.type)}}><img className="boilerplate-icon" src="/dashboard/text-icon.svg" />
                                    <ContentEditableWithFocus
                                        tagName="span"
                                        className="focus-ellipsis"
                                        html={boilerplate.text}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[selectedFolder[0]].contents[index].text = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                        onBlur={() => setEditablePath(null)}
                                        disabled={!isEditable}
                                    />
                                </div>
                                )
                            }
                        })}
                    </div>
                    <div className="boilerplate-section" onContextMenu={(event) => handleContextMenu(event, selectedFolder.slice(0, 2))}>
                        {boilerplates[selectedFolder[0]]?.contents[selectedFolder[1]]?.contents?.map((boilerplate, index) => {
                            const currentPath = [...selectedFolder.slice(0, 2), index]; // 현재 항목의 경로
                            const isEditable = JSON.stringify(editablePath) === JSON.stringify(currentPath)
                            const isActive = JSON.stringify(selectedFolder.slice(0, 3)) === JSON.stringify(currentPath)
                            if(boilerplate.type == "folder"){
                                return (
                                <div key={index} className={`boilerplate-item ${isActive? "active":""}`} onContextMenu={(e) => {e.stopPropagation(); handleContextMenu(e, [...selectedFolder.slice(0, 2), index], boilerplate.type)}} onClick={()=> {selectFolder(2, index)}}><img className="boilerplate-icon" src="/dashboard/folder-icon.svg" />
                                    <ContentEditableWithFocus
                                        tagName="span"
                                        html={boilerplate.name}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[selectedFolder[0]].contents[selectedFolder[1]].contents[index].name = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                        onBlur={() => setEditablePath(null)}
                                        disabled={!isEditable}
                                    />
                                </div>
                            )
                            } else {
                                return (
                                <div key={index} className="boilerplate-item" onContextMenu={(e) => {e.stopPropagation(); handleContextMenu(e, [...selectedFolder.slice(0, 2), index], boilerplate.type)}}><img className="boilerplate-icon" src="/dashboard/text-icon.svg" />
                                    <ContentEditableWithFocus
                                        tagName="span"
                                        className="focus-ellipsis"
                                        html={boilerplate.text}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[selectedFolder[0]].contents[selectedFolder[1]].contents[index].text = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                        onBlur={() => setEditablePath(null)}
                                        disabled={!isEditable}
                                    />
                                </div>
                                )
                            }
                        })}
                    </div>
                    <div className="boilerplate-section" onContextMenu={(event) => handleContextMenu(event, selectedFolder.slice(0, 3))}>
                        {boilerplates[selectedFolder[0]]?.contents[selectedFolder[1]]?.contents[selectedFolder[2]]?.contents?.map((boilerplate, index) => {
                            const currentPath = [...selectedFolder.slice(0, 3), index]; // 현재 항목의 경로
                            const isEditable = JSON.stringify(editablePath) === JSON.stringify(currentPath)
                            return (
                            <div key={index} className="boilerplate-item" onContextMenu={(e) => {e.stopPropagation(); handleContextMenu(e, [...selectedFolder.slice(0, 3), index], boilerplate.type)}}><img className="boilerplate-icon" src="/dashboard/text-icon.svg" />
                                <ContentEditableWithFocus
                                    tagName="span"
                                    className="focus-ellipsis"
                                    html={boilerplate.text}
                                    onChange={(event) => {
                                        const updatedText = event.target.value; // 수정된 텍스트
                                        const updatedBoilerplates = [...boilerplates];
                                        // 선택된 텍스트 업데이트
                                        updatedBoilerplates[selectedFolder[0]].contents[selectedFolder[1]].contents[selectedFolder[2]].contents[index].text = updatedText;
                                        setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                    }}
                                    onBlur={() => setEditablePath(null)}
                                    disabled={!isEditable}
                                />
                            </div>
                            )
                        })}
                    </div>
                </div>
                {/* Render the context menu */}
                {contextMenu && (
                    <ContextMenu
                    x={contextMenu.x}
                    y={contextMenu.y}
                    type={contextMenu.type}
                    position={contextMenu.position}
                    onAddFolder={handleAddFolder}
                    onAddText={handleAddText}
                    onChangeContent={handleChangeContent}
                    onRemove={handleRemoveItem}
                    />
                )}
                <div className="boilerplate-folder-tree">
                    <div className="folder-tree-item">
                        {selectedFolder.map((folderIndex, depth) => {
                            // 현재 폴더를 탐색
                            const folder = selectedFolder
                                .slice(0, depth + 1)
                                .reduce((current, index) => {
                                    if (!current.contents) return current; // contents가 없는 경우 현재 폴더 그대로 반환
                                    return current.contents[index]; // 하위 폴더로 이동
                                }, { contents: boilerplates });

                            // 현재 폴더의 이름을 표시
                            return (
                                <React.Fragment key={depth}>
                                    {depth > 0 && <span className="folder-separator">{">"}</span>}
                                    <img className="boilerplate-icon" src="/dashboard/folder-icon.svg" />
                                    {folder?.name.replace(/<br>/g, "") || "폴더 없음"}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            </div>
            }
        </div>
        </>
    );
}

export default MyPage;