import { useState, useContext } from 'react';
import './ChangePassword.css';
import { changePassword } from '../api/apis.js';
import { ModalContext } from '../components/ModalProvider.js';
import { CREATE_FILE_KEY } from '../constants/modal.js';

function ChangePassword() {
    const [currentPW, setCurrentPW] = useState("");
    const [targettPW, setTargettPW] = useState("");
    const [targettPWAgain, setTargettPWAgain] = useState("");
    const [errorField, setErrorField] = useState(0);
    const [descriptionMsg, setDescriptionMsg] = useState("");
    const [isChanged, setIsChanged] = useState(false);

    const { closeModal } = useContext(ModalContext);

    const clickChangeBtn = () => {
        if(!currentPW | !targettPW | !targettPWAgain) {
            return
        }

        if(targettPW !== targettPWAgain) {
            setErrorField(2)
            setDescriptionMsg("새 비밀번호가 일치하지 않습니다. 다시 입력해주세요.")
            return
        }
        
        changePassword(currentPW, targettPW)
        .then((data) => {
            setIsChanged(true);
        })
        .catch((err) => {
            console.error(err);
            setErrorField(1)
            setDescriptionMsg("현재 비밀번호를 다시 확인해주세요.")
        })
        .finally(() => {
        });
    }

    if (isChanged) {
        return <div className="done-change-wrap">
            <div className="done-change-title">알림</div>
            <div className="done-change-content">비밀번호 변경이 완료되었습니다.</div>
            <div className="done-change-ok" onClick={()=>{closeModal(CREATE_FILE_KEY);}}>확인</div>
        </div>
    }
    return (
        <div className="change-password-container">
            <div className="change-email-contents">
                <div className="change-email-title">비밀번호 변경</div>
                <div className="change-pw-description">{descriptionMsg || " "}</div>
                <div className="change-email-label current-pw">현재 비밀번호</div>
                <input 
                    className={`change-email-target ${errorField == 1 ? "field-error":""}`}
                    placeholder="현재 비밀번호를 입력해주세요"
                    type="password"
                    value={currentPW}
                    onChange={(e) => setCurrentPW(e.target.value)} />
                <div className="change-email-label new-pw">새 비밀번호</div>
                <input 
                    className={`change-email-target ${errorField == 2 ? "field-error":""}`}
                    placeholder="새 비밀번호를 입력해주세요"
                    type="password"
                    value={targettPW}
                    onChange={(e) => setTargettPW(e.target.value)} />
                
                <div className="change-email-label new-pw2">새 비밀번호 확인</div>
                <input 
                    className={`change-email-target ${errorField == 2 ? "field-error":""}`}
                    placeholder="새 비밀번호를 다시 입력해주세요"
                    type="password"
                    value={targettPWAgain}
                    onChange={(e) => setTargettPWAgain(e.target.value)} />
            </div>
            <div className="change-email-btns">
                <div className="change-email-btn" onClick={()=>closeModal(CREATE_FILE_KEY)}>취소</div>
                <div className={`change-email-btn-change ${currentPW && targettPW && targettPWAgain ?"active":""}`} onClick={clickChangeBtn}>변경하기</div>
            </div>
        </div>
    );
}

export default ChangePassword;
