import React, { useEffect, useState } from 'react';
import './ToolbarHome.css'

function ToolbarView({ onCommand }) {
    return (
        <div className="toolbar">
            <div className="toolbar-font-settings">
                <div className="toolbar-font-upper-row">
                    <div className="toolbar-fontfamily">
                        <div className="toolbar-fontfamily-title">바탕</div>
                        <img src="/toolbar/arrow-down.svg" />
                    </div>
                    <div className="toolbar-fontsize">
                        <div className="toolbar-fontsize-title">12</div>
                        <img src="/toolbar/arrow-down.svg" />
                    </div>
                </div>
                <div className="toolbar-font-lower-row">
                    <div className="toolbar-font-setting-icon icon" onClick={(e) => {e.preventDefault(); onCommand('bold')}}>
                        <img src="/toolbar/bold.svg" alt="Bold" />
                    </div>
                    <div className="toolbar-font-setting-icon icon" onClick={(e) => {e.preventDefault(); onCommand('italic')}}>
                        <img src="/toolbar/italic.svg" alt="Italic" />
                    </div>
                    <div className="toolbar-font-setting-icon icon" onClick={(e) => {e.preventDefault(); onCommand('underline')}}>
                        <img src="/toolbar/underline.svg" alt="Underline" />
                    </div>
                    <div className="toolbar-font-setting-icon icon" onClick={(e) => {e.preventDefault(); onCommand('strikeThrough')}}>
                        <img src="/toolbar/cancelline.svg" alt="Strikethrough" />
                    </div>
                    <div className="toolbar-font-setting-icon icon" onClick={(e) => {e.preventDefault(); onCommand('superscript')}}>
                        <img src="/toolbar/위첨자.svg" alt="Superscript" />
                    </div>
                    <div className="toolbar-font-setting-icon icon" onClick={(e) => {e.preventDefault(); onCommand('subscript')}}>
                        <img src="/toolbar/아래첨자.svg" alt="Subscript" />
                    </div>
                </div>
            </div>
            <div className="toolbar-divider"></div>
            <div className="toolbar-paragraph-settings">
                <div className="paragraph-settings-row">
                    <div className="paragraph-icon icon">
                        <img src="/toolbar/왼쪽정렬.svg" />
                    </div>
                    <div className="paragraph-icon icon">
                        <img src="/toolbar/가운데정렬.svg" />
                    </div>
                    <div className="paragraph-icon icon">
                        <img src="/toolbar/우측정렬.svg" />
                    </div>
                    <div className="paragraph-icon icon">
                        <img src="/toolbar/양쪽정렬.svg" />
                    </div>
                </div>
                <div className="paragraph-settings-row">
                    <div className="paragraph-icon icon">
                        <img src="/toolbar/단삭제.svg" />
                    </div>
                    <div className="paragraph-icon icon">
                        <img src="/toolbar/단추가.svg" />
                    </div>
                </div>
            </div>
            <div className="toolbar-divider"></div>
        </div>
    );
}

export default ToolbarView;