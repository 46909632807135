import { request } from './request';

/**
 * @typedef {Object} Sort
 * @property {boolean} empty - 정렬이 비어 있는지 여부
 * @property {boolean} sorted - 정렬 여부
 * @property {boolean} unsorted - 미정렬 여부
 */

/**
 * @typedef {Object} Pageable
 * @property {number} offset - 오프셋
 * @property {Sort} sort - 정렬 정보
 * @property {number} pageNumber - 페이지 번호
 * @property {number} pageSize - 페이지 크기
 * @property {boolean} paged - 페이징 여부
 * @property {boolean} unpaged - 페이징되지 않은 여부
 */

/**
 * 특허 정보 객체 (특허 파일 생성 시)
 * @typedef {Object} PatentInfo
 * @property {string} patentId - 특허 ID
 * @property {string} title - 특허 제목
 * @property {string} client - 의뢰인 이름
 * @property {string} receiptDate - 접수일 (YYYY-MM-DD 형식)
 * @property {string} dueDate - 마감일 (YYYY-MM-DD 형식)
 * @property {string} memo - 메모 내용
 */

/**
 * 특허 정보 객체 (특허 파일 조회 시)
 * @typedef {Object} Patent
 * @property {number} id - 고유 ID
 * @property {string} patentId - 특허 ID
 * @property {string} title - 특허 제목
 * @property {string} client - 의뢰인 이름
 * @property {string} receiptDate - 접수일 (YYYY-MM-DD 형식)
 * @property {string} dueDate - 마감일 (YYYY-MM-DD 형식)
 * @property {string} status - 상태
 * @property {string} memo - 메모 내용
 * @property {string} claim - 클레임 내용
 * @property {string} inventionType - 발명 유형
 * @property {string} file - 파일 경로
 * @property {string} text - 텍스트 설명
 * @property {string} drawings - 도면 파일 경로
 */

/**
 * @typedef {Object} PaginatedPatents
 * @property {number} totalPages - 총 페이지 수
 * @property {number} totalElements - 총 요소 수
 * @property {number} size - 페이지 크기
 * @property {Patent[]} content - 특허 데이터
 * @property {number} number - 현재 페이지 번호
 * @property {Sort} sort - 정렬 정보
 * @property {number} numberOfElements - 현재 페이지의 요소 수
 * @property {Pageable} pageable - 페이징 정보
 * @property {boolean} first - 첫 페이지 여부
 * @property {boolean} last - 마지막 페이지 여부
 * @property {boolean} empty - 데이터가 비어 있는지 여부
 */

const PAGE_SIZE = 12;

const createPageParams = (page, sort) => {
  const params = new URLSearchParams({
    page,
    ...(sort && { sort }),
    size: PAGE_SIZE,
  });

  return params.toString();
};

/**
 * @param {number} page
 * @returns {Promise<PaginatedPatents>}
 */
export const getRequestPatents = async (page) => {
  return request.GET(`/patents?isCompleted=false&sort=dueDate,asc&${createPageParams(page)}`);
};

/**
 * @param {number} page
 * @returns {Promise<PaginatedPatents>}
 */
export const getRequestDonePatents = async (page) => {
  return request.GET(`/patents?isCompleted=true&${createPageParams(page)}`);
};

/**
 * @param {number} fileId
 * @returns {Promise<any>}
 */
export const getRequestPatentTemp = async (fileId) => {
  return request.GET(`/patents/${fileId}/claim/temp`);
};

/**
 * @param {PatentInfo} patent
 * @returns {Promise<void>}
 */
export const postRequestPatents = async (patent) => {
  return request.POST('/patents', patent);
};

/**
 * @param {number} patentId
 * @param {PatentInfo} patent
 * @returns {Promise<void>}
 */
export const patchRequestPatents = async (patentId, patent) => {
  return request.PATCH(`/patents/${patentId}/basic`, patent);
};

/**
 * @returns {Promise<PatentInfo[]>}
 */
export const getRequestLatestPatents = async () => {
  return request.GET(`/patents/latest-updated`);
};

/**
 * @param {number} patentId
 */
export const getPatentDetail = async (patentId) => {
  return request.GET(`/patents/${patentId}`);
};

/**
 * @param {number} fileId
 * @returns {Promise<void>}
 */
export const deleteRequestPatents = async (fileId) => {
  return request.DELETE(`/patents/${fileId}`);
};

/**
 * @param {number} fileId
 * @param {any} detail
 * @returns {Promise<void>}
 */
export const postRequestPatentsDetail = async (fileId, detail) => {
  return request.POST(`/patents/${fileId}`, detail, true);
};

/**
 * @param {number} fileId
 * @param {any} detail
 * @returns {Promise<void>}
 */
export const saveTemporaryClaimAndDrawings = async (fileId, detail) => {
  return request.POST(`/patents/${fileId}/temp`, detail, true);
};

/**
 * @param {number} fileId
 * @param {any} detail
 * @returns {Promise<void>}
 */
export const patchRequestPatentsDetail = async (fileId, detail) => {
  return request.PATCH(`/patents/${fileId}/claim`, detail, true);
};

/**
 * @param {number} fileId
 * @param {any} detail
 * @returns {Promise<void>}
 */
export const patchRequestPatentDrawings = async (fileId, detail) => {
  return request.PATCH(`/patents/${fileId}/drawing`, detail, true);
};

/**
 * @param {number} fileId
 * @returns {Promise<void>}
 */
export const postRequestPatentComplete = async (fileId) => {
  return request.POST(`/patents/${fileId}/complete`);
};

/**
 */
export const getUserInfo = async () => {
  return request.GET(`/users/info`);
};

/**
 */
export const getBoilerplate = async () => {
  return request.GET(`/boilerplate`);
};

/**
 * @param {any} content
 */
export const postBoilerplate = async (content) => {
  return request.POST(`/boilerplate`, {content}, false);
};

/**
 * @param {number} patentId
 * @param {any} text
 */
export const postAItext = async (patentId, text) => {
  return request.PATCH(`/patents/${patentId}/claim/edit`, {text}, false);
};

/**
 * @param {any} email
 */
export const changeEmail = async (email) => {
  return request.PATCH(`/users/email`, {email}, false);
};

/**
 * @param {any} password
 * @param {any} newPassword
 */
export const changePassword = async (password, newPassword) => {
  return request.PATCH(`/users/password`, {password, newPassword}, false);
};

/**
 * @param {any} subscriptionType
 */
export const changePlanRequest = async (subscriptionType) => {
  return request.POST(`/subscriptions`, {subscriptionType}, false);
};

/**
 * @param {any} reason
 */
export const cancelPlanRequest = async ( reason) => {
  return request.DELETE(`/subscriptions/cancel`, {reason}, false);
};