import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './ToolbarHome.css'
import { usePlainContentStore } from '../store.js';
import { saveAs } from 'file-saver';
import { AlignmentType, Document, Packer, Paragraph, TextRun, ImageRun, PageBreak } from 'docx';
import { getPatentDetail } from '../api/apis.js';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ToolbarHome({ onCommand }) {
    const { plainContents, drawings } = usePlainContentStore();
    const [ senitizedText, setSenitizedText ] = useState("");
    const query = useQuery();
    const patentID = query.get('id');

    const downloadWord = async (content, id) => {
        const lines = content.split('\n');
        const paragraphs = [];
        const imageRegex = /!\*(.*?)\*!/;
    
        let filename;
        let client;
        let patentId;
        await getPatentDetail(id)
            .then((data) => {
                filename = data.title;
                client = data.client;
                patentId = data.patentId;
            })
            .catch((err) => {
                console.error(err);
                alert("존재하지 않는 사건번호입니다.");
            });
    
        // 각 줄을 순회하며 페이지 나누기 처리 및 이미지 삽입
        for (const line of lines) {
            if (line.trim().startsWith("@@@")) {
                paragraphs.push(new Paragraph({ children: [new PageBreak()] }));
            } else if (/^(【발명의 설명】|【청구범위】|【요약서】|【도면】)$/.test(line.trim())) {
                const lineChildren = [];
                lineChildren.push(
                    new TextRun({
                        text: line,
                        font: '바탕체',
                        size: 24, // 12pt
                    })
                );
                paragraphs.push(
                    new Paragraph({
                        children: lineChildren,
                        alignment: AlignmentType.CENTER,
                        spacing: { line: 500 },
                    })
                );
            } else if (imageRegex.test(line)) {
                const lineChildren = [];
                const match = imageRegex.exec(line);
                if (match) {
                    try {
                        const { blob, width, height } = await fetchImageBlobWithDimensions(match[1]);
                        const maxWidth = 600;
                        let adjustedWidth = width;
                        let adjustedHeight = height;

                        if (width > maxWidth) {
                            const scale = maxWidth / width;
                            adjustedWidth = maxWidth;
                            adjustedHeight = Math.round(height * scale); // 비율 유지
                        }
                        lineChildren.push(
                            new ImageRun({
                                data: blob,
                                transformation: {
                                    width: adjustedWidth, // 조정된 너비
                                    height: adjustedHeight, // 조정된 높이
                                },
                            })
                        );
                    } catch (error) {
                        console.error(`Error fetching image: ${error}`);
                    }
                }
    
                paragraphs.push(
                    new Paragraph({
                        children: lineChildren,
                        alignment: AlignmentType.JUSTIFIED,
                        spacing: { line: 500 },
                    })
                );
            } else {
                const lineChildren = [];
                const textWithoutImage = line.replace(/\*\*(\d+)\*\*/, ""); // 이미지 태그 제거
    
                // 텍스트 처리
                if (textWithoutImage.trim()) {
                    lineChildren.push(
                        new TextRun({
                            text: textWithoutImage,
                            font: '바탕체',
                            size: 24, // 12pt
                        })
                    );
                }
    
                // 이미지 처리
                const imageRegex = /\*\*(\d+)\*\*/g;
                const match = imageRegex.exec(line);
                if (match) {
                    const index = parseInt(match[1], 10);
                    try {
                        const { blob, width, height } = await fetchImageBlobWithDimensions(drawings[index].url);
                        const maxWidth = 600;
                        let adjustedWidth = width;
                        let adjustedHeight = height;

                        if (width > maxWidth) {
                            const scale = maxWidth / width;
                            adjustedWidth = maxWidth;
                            adjustedHeight = Math.round(height * scale); // 비율 유지
                        }
                        lineChildren.push(
                            new ImageRun({
                                data: blob,
                                transformation: {
                                    width: adjustedWidth, // 조정된 너비
                                    height: adjustedHeight, // 조정된 높이
                                },
                            })
                        );
                    } catch (error) {
                        console.error(`Error fetching image: ${error}`);
                    }
                }
    
                paragraphs.push(
                    new Paragraph({
                        children: lineChildren,
                        alignment: AlignmentType.JUSTIFIED,
                        spacing: { line: 500 },
                    })
                );
            }
        }
    
        const doc = new Document({
            sections: [
                {
                    properties: {},
                    children: paragraphs,
                },
            ],
        });
    
        Packer.toBlob(doc).then((blob) => {
            saveAs(blob, `${patentId}_${filename}_${client}.docx`); // 파일 저장
        });
    };

    const fetchImageBlobWithDimensions = async (url) => {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Failed to fetch image from ${url}`);
        }
        const blob = await response.blob();
        const img = new Image();
    
        return new Promise((resolve, reject) => {
            img.onload = () => {
                resolve({ blob, width: img.width, height: img.height });
                URL.revokeObjectURL(img.src); // URL 해제
            };
            img.onerror = reject;
            img.src = URL.createObjectURL(blob); // Blob을 사용해 이미지 객체 생성
        });
    };

    const handleDownload = () => {
        downloadWord(senitizedText, patentID);
    };

    const applySenitize = (text) => {
        text = text.replace(/&lt;b&gt;(.*?)&lt;\/b&gt;/g, '$1');
        text = text.replace(/&lt;i&gt;(.*?)&lt;\/i&gt;/g, '$1');
        text = text.replace(/&lt;u&gt;(.*?)&lt;\/u&gt;/g, '$1');
        text = text.replace(/&lt;strike&gt;(.*?)&lt;\/strike&gt;/g, '$1');
        text = text.replace(/&lt;sup&gt;(.*?)&lt;\/sup&gt;/g, '$1');
        text = text.replace(/&lt;sub&gt;(.*?)&lt;\/sub&gt;/g, '$1');
        text = text.replace(/!!/g, '!\n!');
        text = text.replace(/\?\?(.*?)\?\?/g, '');
        text = text.replace(/'''(.*?)'''\s*/g, '');
        text = text.replace(/##(.*?)##/g, '');
        text = text.replace(/\^\^(.*?)\^\^/g, "");
        text = text.replace(/\/\/\/(.*?)\/\/\//g, '$1');
        text = text.replace(/!【(.*?)】!/g, '【$1】');
        return text;
    };

    useEffect(()=>{
        console.log(plainContents)
        setSenitizedText(applySenitize(plainContents))
    }, [setSenitizedText, plainContents])

    const handlePaste = async () => {
        try {
            let text = await navigator.clipboard.readText();
            text = text.replace(/\r\n|\r/g, '\n');
            document.execCommand('insertText', false, text);
        } catch (err) {
            console.error('붙여넣기 실패:', err);
            alert('붙여넣기에 실패했습니다. 브라우저 클립보드 접근이 차단되었을 수 있습니다.');
        }
    };

    return (
        <div className="toolbar">
            <div className="toolbar-paste">
                <div className="toolbar-paste-icon big-icon">
                    <img src="/toolbar/붙여넣기.svg" onClick={(e) => { 
                        e.preventDefault(); 
                        handlePaste();
                    }}/>
                </div>
                <div className="toolbar-paste-title">붙여넣기</div>
            </div>
            <div className="toolbar-edit-icons">
                <div className="toolbar-edit-icons-column">
                    <div className="toolbar-icon icon"
                    onClick={(e) => { 
                        e.preventDefault(); 
                        onCommand('cut');
                    }}>
                        <img src="/toolbar/잘라내기.svg" />
                    </div>
                    <div className="toolbar-icon icon" onClick={(e) => { 
                        e.preventDefault(); 
                        onCommand('copy');
                    }}>
                        <img src="/toolbar/복사.svg" />
                    </div>
                    <div className="toolbar-icon icon">
                        <img src="/toolbar/서식복사.svg" />
                    </div>
                </div>
                <div className="toolbar-edit-icons-column">
                    <div className="toolbar-icon icon" onClick={(e) => { 
                        e.preventDefault(); 
                        onCommand('undo');
                    }}>
                        <img src="/toolbar/undo.svg" />
                    </div>
                    <div className="toolbar-icon icon" onClick={(e) => { 
                        e.preventDefault(); 
                        onCommand('redo');
                    }}>
                        <img src="/toolbar/redo.svg" />
                    </div>
                    {/* <div className="toolbar-icon icon">
                        <img src="/toolbar/업로드.svg" />
                    </div> */}
                </div>
            </div>
            <div className="toolbar-divider"></div>
            <div className="toolbar-font-settings">
                <div className="toolbar-font-upper-row">
                    <div className="toolbar-fontfamily">
                        <div className="toolbar-fontfamily-title">바탕</div>
                        <img src="/toolbar/arrow-down.svg" />
                    </div>
                    <div className="toolbar-fontsize">
                        <div className="toolbar-fontsize-title">12</div>
                        <img src="/toolbar/arrow-down.svg" />
                    </div>
                </div>
                <div className="toolbar-font-lower-row">
                    <div className="toolbar-font-setting-icon icon" onClick={(e) => {e.preventDefault(); onCommand('bold')}}>
                        <img src="/toolbar/bold.svg" alt="Bold" />
                    </div>
                    <div className="toolbar-font-setting-icon icon" onClick={(e) => {e.preventDefault(); onCommand('italic')}}>
                        <img src="/toolbar/italic.svg" alt="Italic" />
                    </div>
                    <div className="toolbar-font-setting-icon icon" onClick={(e) => {e.preventDefault(); onCommand('underline')}}>
                        <img src="/toolbar/underline.svg" alt="Underline" />
                    </div>
                    <div className="toolbar-font-setting-icon icon" onClick={(e) => {e.preventDefault(); onCommand('strikeThrough')}}>
                        <img src="/toolbar/cancelline.svg" alt="Strikethrough" />
                    </div>
                    <div className="toolbar-font-setting-icon icon" onClick={(e) => {e.preventDefault(); onCommand('superscript')}}>
                        <img src="/toolbar/위첨자.svg" alt="Superscript" />
                    </div>
                    <div className="toolbar-font-setting-icon icon" onClick={(e) => {e.preventDefault(); onCommand('subscript')}}>
                        <img src="/toolbar/아래첨자.svg" alt="Subscript" />
                    </div>
                </div>
            </div>
            <div className="toolbar-divider"></div>
            <div className="toolbar-add-identifiers">
                <div className="add-identifier icon">
                    <div className="select-identifier">
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '')}}>【】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '도')}}>【도】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '표')}}>【표】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '청구항')}}>【청구항】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '수학식')}}>【수학식】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '화학식')}}>【화학식】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '반응식')}}>【반응식】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '실시예')}}>【실시예】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '특허문헌')}}>【특허문헌】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '수탁기관')}}>【수탁기관】</div>
                    </div>
                    <div className="identifier-icon icon">
                        <img src="/toolbar/식별항목.svg" />
                    </div>
                    <div className="identifier-title">식별항목 추가</div>
                </div>
                <div className="add-identifier icon">
                    <div className="select-identifier">
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '발명의 설명')}}>【발명의 설명】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '발명의 명칭')}}>【발명의 명칭】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '기술분야')}}>【기술분야】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '발명의 배경이 되는 기술')}}>【발명의 배경이 되는 기술】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '발명의 내용')}}>【발명의 내용】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '해결하고자 하는 과제')}}>【해결하고자 하는 과제】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '과제의 해결 수단')}}>【과제의 해결 수단】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '발명의 효과')}}>【발명의 효과】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '도면의 간단한 설명')}}>【도면의 간단한 설명】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '발명을 실시하기 위한 구체적인 내용')}}>【발명을 실시하기 위한 구체적인 내용】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '부호의 설명')}}>【부호의 설명】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '청구범위')}}>【청구범위】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '요약서')}}>【요약서】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '요약')}}>【요약】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '대표도')}}>【대표도】</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '도면')}}>【도면】</div>
                    </div>
                    <div className="identifier-icon icon">
                        <img src="/toolbar/표준식별항목.svg" />
                    </div>
                    <div className="identifier-title">표준식별항목 추가</div>
                </div>
            </div>
            <div className="toolbar-divider"></div>
            <div className="toolbar-paragraph-settings">
                <div className="paragraph-settings-row">
                    <div className="paragraph-icon icon">
                        <img src="/toolbar/왼쪽정렬.svg" />
                    </div>
                    <div className="paragraph-icon icon">
                        <img src="/toolbar/가운데정렬.svg" />
                    </div>
                    <div className="paragraph-icon icon">
                        <img src="/toolbar/우측정렬.svg" />
                    </div>
                    <div className="paragraph-icon icon">
                        <img src="/toolbar/양쪽정렬.svg" />
                    </div>
                </div>
                <div className="paragraph-settings-row">
                    <div className="paragraph-icon icon">
                        <img src="/toolbar/단삭제.svg" />
                    </div>
                    <div className="paragraph-icon icon">
                        <img src="/toolbar/단추가.svg" />
                    </div>
                </div>
            </div>
            <div className="toolbar-divider"></div>
            <div className="toolbar-download" onClick={handleDownload}>
                <div className="toolbar-paste-icon big-icon">
                    <img src="/toolbar/download-icon.svg" />
                </div>
                <div className="toolbar-paste-title">다운로드</div>
            </div>
        </div>
    );
}

export default ToolbarHome;