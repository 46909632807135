import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Login from './Login';
import reportWebVitals from './reportWebVitals';
import { isAuthenticated } from './auth';
import Terms from './Terms'
import Privacy from './Privacy'

const root = ReactDOM.createRoot(document.getElementById('root'));
const getComponentBasedOnPath = () => {
  const path = window.location.pathname; // 현재 URL 경로 가져오기
  if (path === '/terms') {
    return <Terms />;
  } else if (path === '/privacy') {
    return <Privacy />;
  } else {
    return <Login />;
  }
};

root.render(
  isAuthenticated() ? <App /> : getComponentBasedOnPath()
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();