import React, { useState, useEffect } from 'react';
import './Login.css';
import Airtable from 'airtable';
import { login } from './auth/auth';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // 로그인 처리 함수
  const handleLogin = async (e) => {
    e.preventDefault();
    const requestBody = { loginId: username, password: password };

    try {
      const response = await fetch('https://dev-api.drift-patent.com/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...requestBody }),
      });

      const authorization = response.headers.get('authorization');
      if (authorization) {
        login(username, authorization);
      } else {
        console.error('authorization 헤더가 없습니다.');
        alert('비밀번호를 확인해주세요.');
      }
    } catch (error) {
      console.error('에러 발생:', error);
      alert('서버에 문제가 발생하였습니다.');
    }
  };

  const handleInquiry = () => {
    alert(`아래의 경로로 문의 주시면 감사드리겠습니다.

고객센터: 010-4296-2559
문의메일: contact@drift-patent.com`)
}

  return (
    <div className="login-body">
      <div className="login-container">
        <div className="login-left">
          <div className="login-left-title">
            변리사를 위한 새로운
            <br />
            명세서 작성 솔루션
          </div>
          <div className="login-left-subtitle-1">더 빠르게, 더 확실하게.</div>
          <div className="login-left-subtitle-2">
            생성형 AI기술을 통해 초안을 작성하고,
            <br />
            변리사가 검토하여 완성하는 특허명세서
          </div>
          <div className="login-left-inquiry" onClick={() => window.open("https://forms.gle/bsLZHQsap2yg1wYf8", '_blank')}>도입문의</div>
        </div>
        <div className="login-right">
          <img src="/drift-logo-black.svg"></img>
          <form onSubmit={handleLogin}>
            <div className="login-title">로그인</div>
            <div className="login-line">
              <label>아이디</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                disabled={isLoading}
                placeholder="아이디를 입력해주세요"
              />
            </div>
            <div className="login-line">
              <label>패스워드</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={isLoading}
                placeholder="패스워드를 입력해주세요"
              />
            </div>
            <button
              type="submit"
              disabled={!username || !password || isLoading}
            >
              {isLoading ? 'Logging in...' : 'Log in'}
            </button>
            <div className="find-pw-wrap">
              <div className="find-pw-title">비밀번호를 잊으셨나요?</div>
              <div className="find-pw-btn" onClick={handleInquiry}>문의하기</div>
            </div>
            <div className="signin-wrap">
              <div className="find-pw-title">계정이 없으신가요?</div>
              <div className="find-pw-btn" onClick={() => window.open("https://forms.gle/bsLZHQsap2yg1wYf8", '_blank')}>도입 문의하기</div>
            </div>
          </form>
          <div className="copyright">
            <div className="terms-wrap">
              <div className="find-pw-btn" onClick={() => window.open("/terms", '_blank')}>이용약관</div>
              <div className="find-pw-btn" onClick={() => window.open("/privacy", '_blank')}>개인정보 보호정책</div>
            </div>
            Copyright ⓒ 2024 이조솔루션 All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
