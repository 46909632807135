import React, { useState, useEffect, useMemo, useContext } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { ModalContext } from '../components/ModalProvider.js';
import File from './File.js';
import CreateNewFile from '../CreateNewFile.js';
import { CREATE_FILE_KEY } from '../constants/modal.js';
import './FilesContent.css';
import { getRequestPatents } from '../api/apis.js';
import DashboardHeader from './DashboardHeader.js'

function FileList() {
  const [fileList, setFileList] = useState([]);

  const userID = sessionStorage.getItem('userID');

  const [isLoading, setIsLoading] = useState(true);

  const { openModal } = useContext(ModalContext);

  const [currentPage, setCurrentPage] = useState(0); // 현재 페이지 상태
  const [totalPages, setTotalPage] = useState(0); // 현재 페이지 상태


  useEffect(() => {
    getRequestPatents(currentPage)
      .then((data) => {
        const patentList = data.content.map((patent) => {
          const { id, patentId, title, client, dueDate, receiptDate, inventionType, memo, status } = patent;

          return { id, patentId, title, client, dueDate, receiptDate, inventionType, memo, status };
        });

        setTotalPage(data.totalPages);
        setFileList(patentList);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage]);
  const itemsPerPage = 12; // 한 페이지에 표시할 아이템 수

  const paginatedList = useMemo(() => {
    const filteredFiles = fileList?.filter(
      (file) => file['회원정보'] === userID
    );
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredFiles?.slice(startIndex, endIndex);
  }, [fileList, userID, currentPage]);

  const handlePageChange = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  const handleCreateNewFile = () => {
    openModal(CREATE_FILE_KEY, <CreateNewFile />, () => false);
  };

  return (
    <>
      {/* {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 1000,
            flexDirection: 'column',
          }}
        >
          <CircularProgress />
          <div className="wait">Loading</div>
        </Box>
      )} */}
      <DashboardHeader />
      <div className="dashboard-main-wrap">
        <div className="patent-list-title-wrap">
          <div className="patent-list-title">내 사건 목록</div>
          <div className="new-patent-btn" onClick={() => handleCreateNewFile()}>
            <img
              alt="new"
              className="new-patent-icon"
              src="/dashboard/new-patent-icon.svg"
            ></img>
            새 사건 등록
          </div>
        </div>
        <div className="patent-list-wrap">
          <div className="patent-list-header">
            <div className="patent-id">사건번호</div>
            <div className="patent-title">사건명</div>
            <div className="patent-client">클라이언트</div>
            <div className="patent-start">수임일</div>
            <div className="patent-due">초안 송부 기한</div>
            <div className="patent-status">상태</div>
            <div className="patent-action">
              <img alt="sort" src="/dashboard/sort.svg"></img>
            </div>
          </div>
          {fileList.map(
            ({ id, patentId, title, client, receiptDate, dueDate, memo, status }, index) => {
              return (
                <File
                  key={id}
                  id={id}
                  patentId={patentId}
                  title={title}
                  client={client}
                  start={receiptDate}
                  due={dueDate}
                  memo={memo}
                  status={status}
                />
              );
            }
          )}
          {fileList.length == 0 && <div className="no-files">진행중인 사건이 없습니다.</div>}
        </div>
        <div className="pagination-wrap">
          <div
            className="pagination-btn"
            onClick={() => handlePageChange(0)}
            disabled={currentPage === 0}
          >
            <img alt="first" src="/dashboard/go-first-page.svg"></img>
          </div>
          <div
            className="pagination-btn"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 0}
          >
            <img alt="prev" src="/dashboard/prev-page.svg"></img>
          </div>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`pagination-btn ${
                currentPage === index ? 'active' : ''
              }`}
              onClick={() => handlePageChange(index)}
            >
              {index + 1}
            </button>
          ))}
          <div
            className="pagination-btn"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <img alt="next" src="/dashboard/next-page.svg"></img>
          </div>
          <div
            className="pagination-btn"
            onClick={() => handlePageChange(totalPages-1)}
            disabled={currentPage === totalPages-1}
          >
            <img alt="last" src="/dashboard/go-last-page.svg"></img>
          </div>
        </div>
      </div>
    </>
  );
}

export default FileList;
