import './Confirmation.css';

function Confirmation({
  title,
  content,
  onConfirm,
  confirmButtonText = '확인',
}) {
  return (
    <div className="confirmation-container">
      <div className="confirmation-title">{title}</div>
      <div className="confirmation-content">{content}</div>
      <div className="confirmation-button-container">
        <button className="confirmation-cancel-button" onClick={onConfirm}>
          {confirmButtonText}
        </button>
      </div>
    </div>
  );
}

export default Confirmation;
