import { useState, useContext } from 'react';
import './ChangeEmail.css';
import { changeEmail } from '../api/apis.js';
import { ModalContext } from '../components/ModalProvider';
import { CREATE_FILE_KEY } from '../constants/modal.js';

function ChangeEmail({currentEmail}) {
    const [email, setEmail] = useState("");
    const [isChanged, setIsChanged] = useState(false);

    const { closeModal } = useContext(ModalContext);

    const clickChangeBtn = () => {
        if(!email) {
            return
        }
        
        changeEmail(email)
        .then((data) => {
            setIsChanged(true);
        })
        .catch((err) => {
            console.error(err);
        })
        .finally(() => {
        });
    }

    if (isChanged) {
        return <div className="done-change-wrap">
            <div className="done-change-title">알림</div>
            <div className="done-change-content">메일 주소가 변경되었습니다.</div>
            <div className="done-change-ok" onClick={()=>{closeModal(CREATE_FILE_KEY); window.location.reload()}}>확인</div>
        </div>
    }
    return (
        <div className="change-email-container">
            <div className="change-email-contents">
                <div className="change-email-title">E-mail 변경</div>
                <div className="change-email-subtitle">등록된 메일 주소로 <b>중요한 알림 및 안내 메일</b>이 발송됩니다.자주 확인하시는 메일 주소를 등록해주세요.</div>
                <div className="current-email-title">현재 메일 주소</div>
                <div className="current-email">{currentEmail}</div>
                <div className="change-email-label">새 메일 주소</div>
                <input 
                    className="change-email-target"
                    placeholder="새 메일 주소를 입력해주세요"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="change-email-btns">
                <div className="change-email-btn" onClick={()=>closeModal(CREATE_FILE_KEY)}>취소</div>
                <div className={`change-email-btn-change ${email?"active":""}`} onClick={clickChangeBtn}>변경하기</div>
            </div>
        </div>
    );
}

export default ChangeEmail;
