import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useIdentifierListStore, useBoilerplateStore, useUIConfigStore, usePlainContentStore } from './store.js';
import './ContentEditor3.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ToolbarHome from './components/ToolbarHome.js'
import ToolbarInput from './components/ToolbarInput.js'
import ToolbarTools from './components/ToolbarTools.js'
import ToolbarView from './components/ToolbarView.js'
import ToolbarHelp from './components/ToolbarHelp.js'
import TextEditor from './components/TextEditor.js'
import ContentEditable from 'react-contenteditable';
import { ModalContext } from './components/ModalProvider.js';
import { CREATE_FILE_KEY } from './constants/modal.js';
import DrawingSetting from './components/DrawingSetting.js'
import { getBoilerplate, getPatentDetail } from './api/apis.js';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ContextMenu = ({ x, y, type, position, onAddFolder, onAddText, onChangeContent, onRemove }) => {
    console.log(x)
    console.log(y)
    return (
      <div
        className="context-menu-nav"
        style={{ top: y, left: x }}
      >
        {type == "folder" && position.length < 3 &&
        <div className="context-menu-item" onClick={onAddFolder}>
          폴더 추가
        </div>}
        {type == "folder" &&
        <div className="context-menu-item" onClick={onAddText}>
          항목 추가
        </div>}
        <div className="context-menu-item" onClick={onChangeContent}>
          수정
        </div>
        <div className="context-menu-item" onClick={onRemove}>
          삭제
        </div>
      </div>
    );
};

function ContentEditor() {
    const navigate = useNavigate();
    const { identifiers, standardIdentifiers, claims, images } = useIdentifierListStore();
    const {boilerplates, setBoilerplates, removeBoilerplate, toggleOpen} = useBoilerplateStore();
    const { drawings, setDrawings } = usePlainContentStore();
    const [ filename, setFilename ] = useState("")
    const [editablePath, setEditablePath] = React.useState(null); // 현재 편집 가능한 경로
    const [isLoading, setIsLoading] = useState(true);
    const [selectedToolbarOption, setSelectedToolbarOption] = useState("Home")
    const [selectedNavOption, setSelectedNavOption] = useState("index")
    const [selectedAsideOption, setSelectedAsideOption] = useState("image")
    const [toolbarComponent, setToolbarComponent] = useState(<ToolbarHome />)
    const {showLeft, setShowLeft, showRight, setShowRight} = useUIConfigStore();
    const [claimPositions, setClaimPositions] = useState([])
    const [uniqueClaimNumbers, setUniqueClaimNumbers] = useState([])
    const [ selectedDrawingIndex, setSelectedDrawingIndex ] = useState(0);
    const [leftWidth, setLeftWidth] = useState(360);
    const [rightWidth, setRightWidth] = useState(360);
    const [isDragging, setIsDragging] = useState(null);

    const { openModal } = useContext(ModalContext);
    
    const [command, setCommand] = useState(null);

    const applyCommand = (cmd, arg = null) => {
        setCommand({ cmd, arg });
    };


    useEffect(() => {
        if (selectedToolbarOption == "Home") {
            setToolbarComponent(<ToolbarHome onCommand={applyCommand} />)
        } else if (selectedToolbarOption == "Input") {
            setToolbarComponent(<ToolbarInput onCommand={applyCommand} />)
        } else if (selectedToolbarOption == "Tools") {
            setToolbarComponent(<ToolbarTools url={drawings[selectedDrawingIndex]?.url} patentID={patentID} />)
        } else if (selectedToolbarOption == "View") {
            setToolbarComponent(<ToolbarView onCommand={applyCommand} />)
        } else if (selectedToolbarOption == "Help") {
            setToolbarComponent(<ToolbarHelp />)
        } else {
            setToolbarComponent(<ToolbarHome />)
        }
    }, [selectedToolbarOption]);


    const query = useQuery();
    const patentID = query.get('id');

    useEffect(() => {
        getBoilerplate()
        .then((data) => {
            const parsedData = JSON.parse(JSON.parse(Object.values(JSON.parse(data.content)).join("")));
            setBoilerplates(parsedData);
        })
        .catch((err) => {
            console.error(err);
        })
        .finally(() => {
        });
        
        getPatentDetail(patentID)
        .then((data) => {
            setFilename(data.title)
            setDrawings(data.drawingFiles)
        })
        .catch((err) => {
            console.error(err);
            alert("존재하지 않는 사건번호입니다.")
            navigate("/")
        })
        .finally(() => {
            setIsLoading(false);
        });
    }, []);

    const defaultColors = ['red', 'green', 'blue', 'cyan', 'magenta', 'orange', 'purple'];

    const assignColor = (index) => {
        return defaultColors[index % defaultColors.length];
    };

    const [contextMenu, setContextMenu] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState([]);

    const handleContextMenu = (event, position, type) => {
        event.preventDefault();
        setContextMenu({
            position,
            type,
            x: event.clientX,
            y: event.clientY,
        });
    };

    const handleAddFolder = () => {
        if (!contextMenu) return;

        console.log(contextMenu.position)

        if (contextMenu.position.length == 3) {
            alert("더이상 폴더를 추가할 수 없습니다.")
            return
        }
    
        const updatedBoilerplates = [...boilerplates];
        let target = updatedBoilerplates;
    
        // Navigate to the correct level
        contextMenu.position.forEach((pos) => {
          target = target[pos].contents;
        });
    
        // Add new folder
        target.push({
          type: "folder",
          name: "새 폴더",
          contents: [],
          isOpen: true,
        });
    
        setBoilerplates(updatedBoilerplates);
        setContextMenu(null);
    };

    const handleAddText = () => {
        if (!contextMenu) return;
    
        const updatedBoilerplates = [...boilerplates];
        let target = updatedBoilerplates;
    
        // Navigate to the correct level
        contextMenu.position.forEach((pos) => {
          target = target[pos].contents;
        });
    
        // Add new text item
        target.push({
          type: "text",
          name: "새 텍스트",
          text: "여기에 내용을 입력하세요.",
        });
    
        setBoilerplates(updatedBoilerplates);
        setContextMenu(null);
    };

    const handleChangeContent = () => {
        if (!contextMenu) return;

        setEditablePath(contextMenu.position);
    };

    const handleRemoveItem = () => {
        if (!contextMenu) return;
        const confirmDelete = window.confirm("정말로 해당 항목을 삭제하시겠습니까?");
        if (confirmDelete) {
            removeBoilerplate(contextMenu.position);
            setSelectedFolder(selectedFolder.slice(0,contextMenu.position.length-1))
        }
    }

    const [copyMessage, setCopyMessage] = useState(null); // 알림 메시지를 관리할 상태

    const handleClick = (boilerplateText) => {
        navigator.clipboard.writeText(boilerplateText)
            .then(() => {
                setCopyMessage("클립보드에 복사되었습니다."); // 메시지 표시
                setTimeout(() => {
                    setCopyMessage(null);
                }, 1500);
            })
            .catch((error) => {
                console.error("클립보드 복사 실패:", error);
            });
    };

    const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b);

    const makeBoilerplate = (boilerplates, boilerplates_org, path = []) => {
    
        return boilerplates?.map((boilerplate, index) => {
            const currentPath = [...path, index]; // 현재 항목의 경로
    
            const isEditable = JSON.stringify(editablePath) === JSON.stringify(currentPath);
    
            if (boilerplate.type === 'folder') {
                return (
                    <React.Fragment key={index}>
                        <div
                            className="boilerplate-side-item"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!equals(editablePath, currentPath)) {
                                    toggleOpen(currentPath);
                                } else {
                                    // 아무 동작도 하지 않음
                                }
                            }}
                            onContextMenu={(event) => handleContextMenu(event, [...path, index], boilerplate.type)}
                        >
                            <img className="drop-down-icon" src="/nav/drop-down.svg" style={boilerplate.isOpen ? { rotate: "0deg" } : {rotate: "-90deg"}} />
                            <img className="boilerplate-icon" src="/dashboard/folder-icon.svg" />
                            <ContentEditable
                                tagName="span"
                                className="boilerplate-side-item-editable"
                                html={boilerplate.name}
                                disabled={!isEditable} // 현재 경로가 편집 가능 상태인지 확인
                                // onDoubleClick={() => setEditablePath(currentPath)} // 더블클릭 시 편집 가능 상태로 설정
                                onBlur={() => setEditablePath(null)} // 포커스 해제 시 편집 모드 종료
                                onChange={(event) => {
                                    const updatedText = event.target.value; // 수정된 텍스트
                                    const updatedBoilerplates = [...boilerplates_org];
                                
                                    let target = updatedBoilerplates;
                                    for (let i = 0; i < path.length; i++) {
                                        const path_index = path[i];
                                        if (i === path.length - 1) {
                                            // 마지막 경로일 경우
                                            target = target[path_index];
                                        } else {
                                            // 아직 마지막 경로가 아니면 contents를 통해 탐색
                                            target = target[path_index].contents;
                                        }
                                    }
                                    if(path.length == 0) {
                                        target[index].name = updatedText;
                                    } else {
                                        target.contents[index].name = updatedText;
                                    }
                                
                                    setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                }}
                            />
                        </div>
                        {boilerplate.isOpen && (
                            <div className="folder-indent">{makeBoilerplate(boilerplate.contents, boilerplates_org, currentPath)}</div>
                        )}
                    </React.Fragment>
                );
            } else {
                return (
                    <div key={index} className="boilerplate-side-item" onContextMenu={(event) => handleContextMenu(event, [...path, index], boilerplate.type)}>
                        <img className="boilerplate-icon" src="/dashboard/text-icon.svg" />
                        <ContentEditable
                            tagName="span"
                            className={isEditable? "boilerplate-side-item-editable":"boilerplate-side-item-editable ellipsis"}
                            html={boilerplate.text}
                            disabled={!isEditable} // 현재 경로가 편집 가능 상태인지 확인
                            // onDoubleClick={() => setEditablePath(currentPath)} // 더블클릭 시 편집 가능 상태로 설정
                            onBlur={() => setEditablePath(null)} // 포커스 해제 시 편집 모드 종료
                            onClick={() => {
                                if (!equals(editablePath, currentPath)) {
                                    handleClick(boilerplate.text)
                                } else {
                                    // 아무 동작도 하지 않음
                                }
                            }}
                            onChange={(event) => {
                                const updatedText = event.target.value;
                                const updatedBoilerplates = [...boilerplates_org];
                                
                                let target = updatedBoilerplates;
                                for (let i = 0; i < path.length; i++) {
                                    const path_index = path[i];
                                    if (i === path.length - 1) {
                                        target = target[path_index];
                                    } else {
                                        target = target[path_index].contents;
                                    }
                                }
                                if(path.length == 0) {
                                    target[index].text = updatedText;
                                } else {
                                    target.contents[index].text = updatedText;
                                }
    
                                setBoilerplates(updatedBoilerplates);
                            }}
                        />
                    </div>
                );
            }
        });
    };

    const LogOut = () => {
        sessionStorage.setItem('SessionId', null);
        window.location.reload();
    };

    const editorRef = useRef(null); // editor container의 ref

    const scrollToTop = () => {
        if (editorRef.current) {
            editorRef.current.scrollTop = 0;
        }
    };

    // 스크롤을 이동시키는 함수
    const handleNavClick = (index) => {
        // editorRef가 정의되어 있을 경우, index 번째 항목으로 스크롤
        const targetElements = editorRef.current.querySelectorAll(".std-id");
        const targetElement = targetElements[index];
        if (targetElement) {
            targetElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    // 스크롤을 이동시키는 함수
    const handleClaimClick = (claimNumber, index) => {
        // NodeList를 배열로 변환 후 필터링
        const targetElements = Array.from(editorRef.current.querySelectorAll(".claim"))
            .filter((c) => c.textContent.trim() === String(claimNumber));
    
        // index*2로 원하는 요소를 선택
        const targetElement = targetElements[index * 2];
        if (targetElement) {
            targetElement.scrollIntoView({
                behavior: 'auto',
                block: 'start',
            });
        }

        setTimeout(() => {
            if (editorRef.current) {
                editorRef.current.scrollBy(0, -40);
            }
        }, 10); // 스크롤 애니메이션 후 추가로 이동
    };

    const getUniqueClaimNumbers = (claims) => {
        return [...new Set(claims.map((claim) => claim.claimNumber))].sort((a, b) => a - b);
    };

    useEffect(() => {
        const uniqueClaimNumbers = getUniqueClaimNumbers(claims);
        setUniqueClaimNumbers(uniqueClaimNumbers)
        setClaimPositions(uniqueClaimNumbers.map((number) => {return {claimNumber:number, position:0}}))
    }, [claims]);

    const showClaimLeft = (claimNumber) => {
        setClaimPositions((prevPositions) =>
            prevPositions.map((pos) =>
                pos.claimNumber === claimNumber
                    ? { ...pos, position: Math.max(0, pos.position - 1) }
                    : pos
            )
        );
    }

    const showClaimRight = (claimNumber) => {
        const maxPosition = claims.filter((claim) => claim.claimNumber === claimNumber).length - 1;
        setClaimPositions((prevPositions) =>
            prevPositions.map((pos) =>
                pos.claimNumber === claimNumber
                    ? { ...pos, position: Math.min(maxPosition, pos.position + 1) }
                    : pos
            )
        );
    }

    const handleMouseDown = (side) => {
        setIsDragging(side);
      };
    
    const handleMouseMove = (e) => {
        if (!isDragging) return;
    
        if (isDragging === "left") {
          const newWidth = e.clientX;
          if (newWidth >= 260) {
            setLeftWidth(newWidth);
          }
        } else if (isDragging === "right") {
          const newWidth = window.innerWidth - e.clientX;
          if (newWidth >= 260) {
            setRightWidth(newWidth);
          }
        }
    };
    
    const handleMouseUp = () => {
        setIsDragging(null);
    };

    return (
        <div className="content-editor-wrap">
            {isLoading && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 1000,
                        flexDirection:'column',
                    }}
                >
                    <CircularProgress />
                    <div className="wait">Loading</div>
                </Box>
            )}
            <div className="editor-fixed-headers">
                <div className="editor-header">
                    <div className="editor-header-left">
                        <div className='home-icon' onClick={() => {navigate(`/`)}}>
                            <img src={process.env.PUBLIC_URL +'/home.svg'}/>
                        </div>
                    </div>
                    <div className="editor-header-center">
                        <div className='editor-header-title'>{filename}</div>
                        <div className="more-icon">
                            <img src={process.env.PUBLIC_URL + "/more.svg"} />
                        </div>
                    </div>
                    <div className="editor-header-right">
                        <div className="user-name">{sessionStorage.getItem('userID')}</div>
                        <div className="editor-logout-button" onClick={LogOut}>로그아웃</div>
                    </div>
                </div>
                <div className="editor-toolbar-selector">
                    <div className={selectedToolbarOption == "Home" ? "toolbar-option active": "toolbar-option"} onClick={() => setSelectedToolbarOption("Home")}>
                        <div className="option-text">홈</div>
                    </div>
                    <div className={selectedToolbarOption == "Input" ? "toolbar-option active": "toolbar-option"} onClick={() => setSelectedToolbarOption("Input")}>
                        <div className="option-text">입력</div>
                    </div>
                    <div className={selectedToolbarOption == "Tools" ? "toolbar-option active": "toolbar-option"} onClick={() => setSelectedToolbarOption("Tools")}>
                        <div className="option-text">도구</div>
                    </div>
                    <div className={selectedToolbarOption == "View" ? "toolbar-option active": "toolbar-option"} onClick={() => setSelectedToolbarOption("View")}>
                        <div className="option-text">보기</div>
                    </div>
                    <div className={selectedToolbarOption == "Help" ? "toolbar-option active": "toolbar-option"} onClick={() => setSelectedToolbarOption("Help")}>
                        <div className="option-text">도움말</div>
                    </div>
                </div>
                {toolbarComponent}
            </div>
            <div className="main-wrap" onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} >
                {showLeft ? 
                <>
                <nav className="nav" style={{ width: `${leftWidth}px` }}>
                    <div className="nav-header">
                        <div className="nav-header-left">
                            <div className={selectedNavOption == "index" ? "nav-header-item active": "nav-header-item"} onClick={() => setSelectedNavOption("index")}>
                                <div className="nav-header-item-icon">
                                    <img src="/nav/index.svg" />
                                </div>
                                <div className="nav-header-item-title">
                                    목차
                                </div>
                            </div>
                            <div className={selectedNavOption == "claim" ? "nav-header-item active": "nav-header-item"} onClick={() => setSelectedNavOption("claim")}>
                                <div className="nav-header-item-icon">
                                    <img src="/nav/show-claim.svg" />
                                </div>
                                <div className="nav-header-item-title">
                                    청구항별 보기
                                </div>
                            </div>
                        </div>
                        <div className="nav-header-right">
                            <div className="fold-icon" onClick={()=> setShowLeft(false)}>
                                <img src="/nav/fold.svg" />
                            </div>
                        </div>
                    </div>
                    <div className="nav-main">
                        {/* {selectedNavOption == "index" && standardIdentifiers[0]?.trim() !== "【발명의 설명】" && <div
                                        onClick={() => scrollToTop()}
                                        className={`nav-main-item padding-4`}
                                    >
                                        【발명의 설명】
                                    </div>} */}
                        {selectedNavOption == "index" && standardIdentifiers?.map((standardIdentifier, index) => {
                            return (
                                standardIdentifier && (
                                    <div
                                        onClick={() => handleNavClick(index)}
                                        className={`nav-main-item ${
                                            /^\d/.test(standardIdentifier.trim()) ? "padding-20" : 
                                            /^\(/.test(standardIdentifier.trim()) ? "padding-36" :
                                            /^(【해결하고자 하는 과제】|【과제의 해결 수단】|【발명의 효과】)/.test(standardIdentifier.trim()) ? "padding-20" : 
                                            /^(【발명의 설명】|【청구범위】|【요약서】|【도면】)$/.test(standardIdentifier.trim()) ? "padding-4" : 
                                            ""
                                        }`}
                                        key={index}
                                    >
                                        {standardIdentifier}
                                    </div>
                                )
                            );
                        })}
                        {selectedNavOption == "claim" && uniqueClaimNumbers?.map((claimNumber, index) => {
                            const claimPosition = claimPositions.find((c)=> c.claimNumber == claimNumber).position
                            const filteredClaims = claims.filter((claim) => claim.claimNumber === claimNumber)
                            const maxPosition = claims.filter((claim) => claim.claimNumber === claimNumber).length - 1;
                            return (
                                <div key={index} className="nav-claim-group">
                                    <div className="nav-claim-group-header">
                                        <div className="nav-claim-title">청구항 {claimNumber}</div>
                                        <div className="nav-claim-position-selector">
                                            <img src={claimPosition == 0? "/nav/claim-left-arrow-gray.svg": "/nav/claim-left-arrow.svg"} onClick={()=>{showClaimLeft(claimNumber)}} />
                                            <img src={claimPosition >= maxPosition ? "/nav/claim-right-arrow-gray.svg":"/nav/claim-right-arrow.svg"} onClick={()=>{showClaimRight(claimNumber)}} />
                                        </div>
                                    </div>
                                    <div className="nav-claim-group-items-wrap">
                                        <div className="nav-claim-group-items" style={{marginLeft:`-${claimPosition*124}px`}}>
                                            {filteredClaims.map((claim, index) => {
                                                return <div className="nav-claim-item" onClick={() => handleClaimClick(claimNumber, index)} key={index}>
                                                        <div className="nav-claim-item-content">{claim.content}</div>
                                                    </div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </nav>
                <div className="resizer-left" onMouseDown={() => handleMouseDown("left")}></div>
                </>
                :
                <div className="folded-nav">
                    <div className="folded-nav-top">
                        <div className="nav-header-item-icon" onClick={() => {setSelectedNavOption("index"); setShowLeft(true)}}>
                            <img src="/nav/index.svg" />
                        </div>
                        <div className="folded-nav-divider" />
                        <div className="nav-header-item-icon"  onClick={() => {setSelectedNavOption("claim"); setShowLeft(true)}}>
                            <img src="/nav/show-claim.svg" />
                        </div>
                    </div>
                    <div className="folded-nav-bottom">
                        <div className="fold-icon" onClick={()=> setShowLeft(true)}>
                            <img src="/nav/fold.svg" />
                        </div>
                    </div>
                </div>
                }
                <main className="main">
                    <TextEditor command={command} ref={editorRef}/>
                </main>
                {showRight ? 
                <>
                <div className="resizer-right" onMouseDown={() => handleMouseDown("right")}></div>
                <aside className="aside" style={{ width: `${rightWidth}px` }}>
                    <div className="nav-header">
                        <div className="nav-header-left">
                            <div className={selectedAsideOption == "image" ? "nav-header-item active": "nav-header-item"} onClick={() => setSelectedAsideOption("image")}>
                                <div className="nav-header-item-icon">
                                    <img src="/nav/image.svg" />
                                </div>
                                <div className="nav-header-item-title">
                                    도면
                                </div>
                            </div>
                            <div className={selectedAsideOption == "boilerplate" ? "nav-header-item active": "nav-header-item"} onClick={() => setSelectedAsideOption("boilerplate")}>
                                <div className="nav-header-item-icon">
                                    <img src="/nav/boilerplate.svg" />
                                </div>
                                <div className="nav-header-item-title">
                                    보일러 플레이트
                                </div>
                            </div>
                        </div>
                        <div className="nav-header-right">
                            <div className="fold-icon" onClick={()=> setShowRight(false)}>
                                <img src="/nav/fold.svg" />
                            </div>
                        </div>
                    </div>
                    <div className="nav-main" onClick={() => {setContextMenu(null)}} style={selectedAsideOption == "image"?{padding:0, overflow:"hidden"}:{}}>
                        {selectedAsideOption == "image" && (
                            <div className="aside-drawing-wrap">
                                { drawings.length != 0 &&
                                <>
                                <div className="aside-main-drawing-wrap">
                                    <img className="aside-drawing-arrow" src="/nav/drawing-left-arrow.svg" onClick={() => {setSelectedDrawingIndex((prev) => Math.max(prev - 1, 0))}} />
                                    <div className="aside-main-drawing-center">
                                        <div className="aside-main-drawing">
                                            <img src={drawings[selectedDrawingIndex].url} onClick={() => window.open(drawings[selectedDrawingIndex].url, '_blank', 'width=800,height=600')} />
                                        </div>
                                        <div className="aside-main-drawing-title">{drawings[selectedDrawingIndex].name}</div>
                                    </div>
                                    <img className="aside-drawing-arrow" src="/nav/drawing-right-arrow.svg" onClick={() => {setSelectedDrawingIndex((prev) => Math.min(prev + 1, drawings.length - 1))}} />
                                </div>
                                <div className="aside-drawing-list-wrap">
                                    {drawings?.map((drawing, index) => {
                                        return  <div key={index} className="aside-drawing-item" onClick={() => setSelectedDrawingIndex(index)}>
                                                    <img src={drawing.url} />
                                                </div>
                                    })}
                                </div>
                                <div className="aside-drawing-footer">
                                    <img className="setting-icon" src="/nav/setting-icon.svg" onClick={() => openModal(CREATE_FILE_KEY, <DrawingSetting fileId={patentID} />, () => false)}/>
                                </div>
                                </>}
                                {drawings.length == 0 && <div className="no-drawings">도면이 없습니다.</div>}
                            </div>
                        )}
                        {selectedAsideOption == "boilerplate" && makeBoilerplate(boilerplates, boilerplates)}
                        {contextMenu && (
                        <ContextMenu
                            x={contextMenu.x}
                            y={contextMenu.y}
                            type={contextMenu.type}
                            position={contextMenu.position}
                            onAddFolder={handleAddFolder}
                            onAddText={handleAddText}
                            onChangeContent={handleChangeContent}
                            onRemove={handleRemoveItem}
                            />
                        )}
                        {copyMessage && (
                            <div className="copy-notification">
                                <img className="clipboard-check-icon" src='/editor/clipboard-check.svg'/>{copyMessage}
                            </div>
                        )}
                    </div>
                </aside>
                </>
                :
                <div className="folded-aside">
                    <div className="folded-nav-top">
                        <div className="nav-header-item-icon" onClick={() => {setSelectedAsideOption("image"); setShowRight(true)}}>
                            <img src="/nav/image.svg" />
                        </div>
                        <div className="folded-nav-divider" />
                        <div className="nav-header-item-icon"  onClick={() => {setSelectedAsideOption("boilerplate"); setShowRight(true)}}>
                            <img src="/nav/boilerplate.svg" />
                        </div>
                    </div>
                    <div className="folded-nav-bottom">
                        <div className="fold-icon" onClick={()=> setShowRight(true)}>
                            <img src="/nav/fold.svg" />
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    );
}

export default ContentEditor;