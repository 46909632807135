import { create } from "zustand";
import { persist } from 'zustand/middleware';
import { shared } from 'use-broadcast-ts'
import { debounce } from 'lodash';
import { postBoilerplate } from './api/apis.js';

const initialPlainContents = ``

export const usePlainContentStore = create(
  persist(
    (set, get) => ({
      plainContents: initialPlainContents,

      setPlainContents: (plainContents) => set({ plainContents: plainContents }),
      drawings: [],

      setDrawings: (drawings) => set({ drawings: drawings }),
    }),
    {
      name: 'plain-contents-store', // 로컬 스토리지 키 이름
      getStorage: () => localStorage, // 기본적으로 로컬 스토리지 사용
    }
  )
);

export const useUIConfigStore = create(
  persist(
    (set, get) => ({
      showLeft: true,
      showRight: true,
      setShowLeft: (showLeft) => set({ showLeft: showLeft }),
      setShowRight: (showRight) => set({ showRight: showRight }),
    }),
    {
      name: 'UI-config-store', // 로컬 스토리지 키 이름
      getStorage: () => localStorage, // 기본적으로 로컬 스토리지 사용
    }
  )
);

export const useIdentifierListStore = create(
  persist(
    (set, get) => ({
      identifiers: [],
      standardIdentifiers: [],
      claims: [],
      images: [],

      setIdentifiers: (identifiers) => set({ identifiers: identifiers }),
      setStandardIdentifiers: (standardIdentifiers) => set({ standardIdentifiers: standardIdentifiers }),
      setClaims: (claims) => set({ claims: claims }),
      setImages: (images) => set({ images: images }),
    }),
    {
      name: 'patent-list-store', // 로컬 스토리지 키 이름
      getStorage: () => localStorage, // 기본적으로 로컬 스토리지 사용
    }
  )
);

const fileTreeEample = [
      {
          "name": "업무 관련",
          "type": "folder",
          "contents": [
          {
              "type": "folder",
              "name": "보고서",
              "contents": [
              {
                  "type": "text",
                  "name": "주간 보고",
                  "text": "이번 주 주요 진행 상황은 다음과 같습니다..."
              },
              {
                  "type": "text",
                  "name": "월간 보고",
                  "text": "지난 한 달간의 주요 활동은 다음과 같습니다..."
              }
              ]
          },
          {
              "type": "folder",
              "name": "회의록",
              "contents": [
                {
                    "type": "folder",
                    "name": "회의주제1",
                    "contents": [
                    {
                        "type": "text",
                        "name": "팀 회의록1",
                        "text": "회의주제 1은 이거였음"
                    }
                    ]
              },
              {
                  "type": "text",
                  "name": "팀 회의록",
                  "text": "팀 회의 주요 논의 사항은 다음과 같습니다..."
              }
              ]
          }
          ]
      },
      {
          "name": "개인 용도",
          "type": "folder",
          "contents": [
          {
              "type": "text",
              "name": "자기소개",
              "text": "안녕하세요, 저는 ..."
          },
          {
              "type": "folder",
              "name": "메모",
              "contents": [
              {
                  "type": "text",
                  "name": "할 일",
                  "text": "1. 장보기\n2. 운동하기"
              }
              ]
          }
          ]
      }
]

export const useBoilerplateStore = create(
  persist(
    (set, get) => {
      const debouncedPostBoilerplate = debounce((boilerplates) => {
        postBoilerplate(JSON.stringify({ ...boilerplates }))
          .then(() => {
            console.log("Saved successfully");
          })
          .catch((error) => {
            console.error("Error saving boilerplates:", error);
          });
      }, 2000);

      const callPostBoilerplate = (boilerplates) => {
          postBoilerplate(JSON.stringify({ ...boilerplates }))
            .then(() => {
              console.log("Saved successfully");
            })
            .catch((error) => {
              console.error("Error saving boilerplates:", error);
            });
        }

      return {
      boilerplates: fileTreeEample,

      setBoilerplates: (boilerplates) => {
        // 상태는 즉시 업데이트
        set({ boilerplates });

        // 디바운스된 postBoilerplate 호출
        debouncedPostBoilerplate(JSON.stringify(boilerplates));
      },
      
      toggleOpen: (path) =>
        set((state) => {
          const updateFolder = (items, currentPath) => {
            return items.map((item, index) => {
              if (item.type === 'folder') {
                if (path.join('.') === [...currentPath, index].join('.')) {
                  // 현재 클릭한 폴더의 isOpen 값을 토글
                  return { ...item, isOpen: !item.isOpen };
                } else if (item.contents) {
                  // 하위 폴더도 재귀적으로 탐색
                  return { ...item, contents: updateFolder(item.contents, [...currentPath, index]) };
                }
              }
              return item;
            });
          };
          return {
            boilerplates: updateFolder(state.boilerplates, []),
          };
        }),

      removeBoilerplate: (position) => {
        const boilerplates = [...get().boilerplates]; // 상태 복사
        let currentLevel = boilerplates;

        // position 배열을 순회하며 대상 항목 탐색
        for (let i = 0; i < position.length - 1; i++) {
          currentLevel = currentLevel[position[i]].contents;
        }

        // 마지막 요소 삭제
        currentLevel.splice(position[position.length - 1], 1);

        callPostBoilerplate(boilerplates);

        // 상태 업데이트
        set({ boilerplates });
      },
    }},
    {
      name: 'boilerplates-store', // 로컬 스토리지 키 이름
      getStorage: () => localStorage, // 기본적으로 로컬 스토리지 사용
    }
  )
);