import React, { useState } from 'react';
import './SelectBox.css';

const SelectBox = ({
  options,
  selectedOption,
  setSelectedOption,
  width = '100%',
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div
      className="selectBox-container"
      style={{ width }}
      tabIndex={0}
      onBlur={() => setIsOpen(false)}
    >
      <div className="selectBox-box" onClick={toggleDropdown}>
        <span>{selectedOption}</span>
        <span>
          <img
            style={{ transform: isOpen && 'rotate(-180deg)' }}
            className="selectBox-arrow"
            alt="arrow"
            src="/toolbar/selectbox-arrow.svg"
          />
        </span>
      </div>

      {isOpen && (
        <ul className="selectBox-dropdown">
          {options.map((option, index) => (
            <li
              key={index}
              className="selectBox-option"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectBox;
