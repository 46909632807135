import React, { useState, useEffect, useMemo, useContext } from 'react';
import './FilesContent.css';
import { useNavigate } from 'react-router-dom';
import { usePatentListStore } from '../store.js';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { ModalContext } from '../components/ModalProvider.js';
import CreateNewFile from '../CreateNewFile.js';
import { CREATE_FILE_KEY } from '../constants/modal.js';
import File from './File.js';
import { getRequestPatents, getRequestLatestPatents } from '../api/apis.js';
import DashboardHeader from './DashboardHeader.js'

function DashBoard() {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [latestFileList, setLatestFileList] = useState([]);
  const userID = sessionStorage.getItem('userID');
  const [isLoading, setIsLoading] = useState(true);

  const { openModal } = useContext(ModalContext);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0); // 현재 페이지 상태

  useEffect(() => {
    const fetchAllPages = async () => {
      setIsLoading(true); // 로딩 시작
      try {
        // 첫 페이지를 가져와 totalPages를 확인
        const firstPageData = await getRequestPatents(0);
        const totalPages = firstPageData.totalPages;
  
        // 첫 페이지 데이터 변환
        const firstPagePatents = firstPageData.content.map((patent) => {
          const {
            id,
            patentId,
            title,
            client,
            dueDate,
            receiptDate,
            inventionType,
            memo,
            status,
          } = patent;
  
          return {
            id,
            patentId,
            title,
            client,
            dueDate,
            receiptDate,
            inventionType,
            memo,
            status,
          };
        });
  
        // 나머지 페이지 요청 생성
        const pagePromises = [];
        for (let i = 1; i < totalPages; i++) {
          pagePromises.push(getRequestPatents(i));
        }
  
        // 모든 페이지 데이터 가져오기
        const remainingPagesData = await Promise.all(pagePromises);
  
        // 나머지 페이지 데이터 변환
        const remainingPatents = remainingPagesData.flatMap((page) =>
          page.content.map((patent) => {
            const {
              id,
              patentId,
              title,
              client,
              dueDate,
              receiptDate,
              inventionType,
              memo,
              status,
            } = patent;
  
            return {
              id,
              patentId,
              title,
              client,
              dueDate,
              receiptDate,
              inventionType,
              memo,
              status,
            };
          })
        );
  
        // 전체 데이터를 합쳐서 상태에 저장
        setFileList([...firstPagePatents, ...remainingPatents]);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false); // 로딩 종료
      }
    };
  
    fetchAllPages();
  }, []); // 의존성 배열을 비워서 한 번만 실행

  useEffect(() => {
    getRequestLatestPatents()
      .then((data) => {
        const patentList = data.map((patent) => {
          const { id, patentId, title, client, dueDate, receiptDate } = patent;

          return { id, patentId, title, client, dueDate, receiptDate };
        });

        setLatestFileList(patentList);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const totalPages = useMemo(() => {
    const totalItems =
      fileList?.filter((file) => file['회원정보'] === userID)?.length || 0;
    return Math.ceil(totalItems / 12);
  }, [fileList, userID]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleCreateNewFile = () => {
    navigate(`/new`);
  };

  const editPatent = (id) => {
    navigate(`/edit/?id=${id}`);
  };

  const editContent = (id) => {
    navigate(`/contentEditor/?id=${id}`);
  };

  return (
    <>
      {/* {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 1000,
            flexDirection: 'column',
          }}
        >
          <CircularProgress />
          <div className="wait">Loading</div>
        </Box>
      )} */}
      <DashboardHeader />
      <div className="dashboard-main-wrap">
        <div className="patent-list-title-wrap">
          <div className="patent-list-title">대시보드</div>
        </div>
        <div className="recent-patent-wrap">
          <div className="recent-patent-title">최근 수정한 문서</div>
          <div className="recent-patent-list">
            <div
              className="new-patent-card patent-card"
              onClick={() =>
                openModal(CREATE_FILE_KEY, <CreateNewFile />, () => false)
              }
            >
              <img
                className="new-patent-card-icon"
                src="/dashboard/new-patent-card-icon.svg"
              />
              <div className="new-patent-card-title">새 사건 등록</div>
            </div>
            {latestFileList
              .slice(0, 2)
              .map(
                (
                  { id, patentId, title, client, receiptDate, dueDate, status },
                  index
                ) => {
                  return (
                    <div key={index} className="recent-patent-card patent-card" onClick={() => {
                      if (status === 'WAITING_INPUT') {
                        alert('처리중입니다');
                        return;
                      }
                      editContent(id);
                    }}>
                      <div className="recent-patent-id">{patentId}</div>
                      <div
                        className="recent-patent-title"
                        
                      >
                        {title}
                      </div>
                      <div className="recent-patent-client">{client}</div>
                      <div className="recent-patent-dates">
                        <div>{receiptDate}</div>
                        <img
                          className="patent-card-arrow-icon"
                          src="/dashboard/patent-card-arrow-icon.svg"
                        />
                        <div>{dueDate}</div>
                      </div>
                    </div>
                  );
                }
              )}
          </div>
        </div>
        <div className="urgent-patent-list-wrap">
          <div className="urgent-patent-list-title">마감 기한 임박 사건</div>
          {fileList
          .filter(({ dueDate }) => {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const nextWeek = new Date();
            nextWeek.setDate(today.getDate() + 7);

            const dueDateObj = new Date(dueDate); // 문자열을 Date 객체로 변환
            return dueDateObj >= today && dueDateObj <= nextWeek; // 조건: 오늘부터 7일 이내
          })
          .map(
            ({ id, patentId, title, client, receiptDate, dueDate, status }) => {
              return (
                <File
                  key={id}
                  id={id}
                  patentId={patentId}
                  title={title}
                  client={client}
                  start={receiptDate}
                  due={dueDate}
                  status={status}
                />
              );
            }
          )}
          {fileList
          .filter(({ dueDate }) => {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const nextWeek = new Date();
            nextWeek.setDate(today.getDate() + 7);

            const dueDateObj = new Date(dueDate); // 문자열을 Date 객체로 변환
            return dueDateObj >= today && dueDateObj <= nextWeek; // 조건: 오늘부터 7일 이내
          }).length == 0 && <div className="no-files">1주일 이내 마감 예정인 사건이 없습니다.</div>}
        </div>
      </div>
    </>
  );
}

export default DashBoard;
