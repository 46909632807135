import { useState } from 'react';

function DashboardHeader() {
    const [isOpen, setIsOpen] = useState(false);
    const [alarmList, setAlarmList] = useState([]);

    const toggleOpen = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className="dashboard-header-wrap">
            <div className="dashboard-header-right">
                <div className="dashboard-user-name">
                    {sessionStorage.getItem('userID')}
                </div>
                <div className="greetings">님 안녕하세요!</div>
                <div className="alarm-btn-wrap">
                    <img className={`alarm-icon ${isOpen? "active":""}`} src="/dashboard/alarm-icon.svg" onClick={toggleOpen}/>
                    {isOpen &&
                        <div className="alarm-list-wrap">
                            <div className="alarm-list-title">알림</div>
                            <div className="alarm-list-contents">
                                {alarmList.map((alarm, index) => {
                                    return (
                                    <div className="alarm-item">
                                        <div className="alarm-checked"></div>
                                        <div className="alarm-item-content"></div>
                                        <div className="alarm-item-data"></div>
                                    </div>
                                )
                                })}
                                {alarmList.length == 0 && <div className="alarm-empty">알림 내역이 없습니다.</div>}
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    );
}

export default DashboardHeader;
