import FilesContent from './files-components/FilesContent';
import './FileSelection.css';
import React, { useState, useEffect, useMemo } from 'react';
import { useBoilerplateStore } from './store.js';
import { getBoilerplate } from './api/apis.js';

function FileSelection() {
  const { setBoilerplates } = useBoilerplateStore();

  useEffect(() => {
      getBoilerplate()
      .then((data) => {
        const parsedData = JSON.parse(JSON.parse(Object.values(JSON.parse(data.content)).join("")));
        setBoilerplates(parsedData);
      })
      .catch((err) => {
          console.error(err);
      })
      .finally(() => {
      });
  }, []);

  return (
    <div className="file-selection-body">
      <FilesContent />
    </div>
  );
}

export default FileSelection;
