import React, { useState, useEffect } from 'react';
import './CreateNewFile.css';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from './Header.js';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function EditFile() {
    const navigate = useNavigate();

    const query = useQuery();
    const patentID = query.get('id');

    const [title, setTitle] = useState('');
    const [client, setClient] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [claim, setClaim] = useState('');
    const [inventionContent, setInventionContent] = useState('');
    const [filePreviews, setFilePreviews] = useState([]);

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const previews = files.map((file) => URL.createObjectURL(file));
        setFilePreviews(previews);
      };

    const SaveAndGoHome = () => {
        if (!patentID || !title || !client || !dueDate || !claim || !inventionContent) {
            alert('도면을 제외한 모든 입력값들을 채워주세요.');
            return;
        }

        const updatedPatent = {
            id: patentID,
            title: title,
            client: client,
            due: dueDate,
            claim: claim,
            inventionContent: inventionContent,
            drawing: filePreviews
        };

        navigate(`/`);
    }

    return (
        <>
        <Header />
        <div className="create-file-title">특허 수정하기</div>
        <div className="create-file-wrap">
            <div className="content-header">
                <div className="content-column">
                    <div className="content-title">사건 번호</div>
                    <input
                        className="content-input"
                        placeholder="123"
                        value={patentID}
                        readOnly
                    />
                </div>
                <div className="content-title-wrap content-column">
                    <div className="content-title">제목</div>
                    <input
                        className="content-input"
                        placeholder="ex. 커피 머신"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div className="content-column">
                    <div className="content-title">클라이언트</div>
                    <input
                        className="content-input"
                        placeholder="삼성전자"
                        value={client}
                        onChange={(e) => setClient(e.target.value)}
                    />
                </div>
                <div className="content-column">
                    <div className="content-title">마감기한</div>
                    <input
                        className="content-input"
                        type="date"
                        value={dueDate}
                        onChange={(e) => setDueDate(e.target.value)}
                    />
                </div>
            </div>
            <div className="content-main">
                <div className="content-main-left">
                    <div className="patent-claim-title content-title">청구항</div>
                    <textarea
                        className="patent-claim"
                        placeholder="청구항을 입력하세요."
                        value={claim}
                        onChange={(e) => setClaim(e.target.value)}
                    />
                </div>
                <div className="content-main-right">
                    <div className="patent-content-wrap">
                        <div className="patent-content-title-wrap">
                            <div className="patent-content-title content-title">발명 내용</div>
                            {/* <div className="edit-content-btn"><img className="btn-logo" src={process.env.PUBLIC_URL + '/aqua-logo.svg'} alt="로고"></img> AQUA 편집기</div> */}
                        </div>
                        <textarea
                            className="patent-content"
                            placeholder="발명의 내용을 입력하세요."
                            value={inventionContent}
                            onChange={(e) => setInventionContent(e.target.value)}
                        />
                    </div>
                    <div className="patent-drawing-wrap">
                        <div className="patent-drawing-title content-title">도면</div>
                        <input
                            className="patent-drawing"
                            type="file"
                            multiple
                            onChange={handleFileChange}
                        />
                        {filePreviews.length > 0 && (
                            <div className="file-previews">
                            {filePreviews.map((preview, index) => (
                                <div key={index} className="file-preview">
                                <img src={preview} alt={`도면 미리보기 ${index + 1}`} />
                                </div>
                            ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <button className="save-btn" onClick={SaveAndGoHome}>저장하기</button>
        </div>
        </>
    );
}

export default EditFile;