import { useState, useContext, useEffect } from 'react';
import './ChangePlan.css';
import { changePlanRequest } from '../api/apis.js';
import { ModalContext } from '../components/ModalProvider';
import { CREATE_FILE_KEY } from '../constants/modal.js';

function ChangePlan({plan}) {
    const [selectedPlan, setSelectedPlan] = useState(plan);
    const [isChanged, setIsChanged] = useState(false);

    const { closeModal } = useContext(ModalContext);

    useEffect(() => {
        setSelectedPlan(plan);
    }, [plan]);

    const clickChangeBtn = () => {
        changePlanRequest(selectedPlan)
        .then((data) => {
            setIsChanged(true);
        })
        .catch((err) => {
            console.error(err);
            alert("이미 업그레이드 신청이 완료 되었습니다.")
        })
        .finally(() => {
        });
    }

    if (isChanged) {
        return <div className="done-change-wrap">
            <div className="done-change-title">알림</div>
            <div className="done-change-content">플랜 업그레이드 신청이 완료되었습니다</div>
            <div className="done-change-ok" onClick={()=>{closeModal(CREATE_FILE_KEY); window.location.reload()}}>확인</div>
        </div>
    }
    return (
        <div className="change-plan-container">
            <div className="change-email-contents">
                <div className="change-email-title">플랜 업그레이드 신청</div>
                <div className="change-email-subtitle">플랜 업그레이드를 신청해주시면, 영업일 기준 3일 이내에 처리해드리고 있습니다</div>
                <div className="change-plan-cards-wrap">
                    <div className={`plan-card ${selectedPlan == "BASIC_PLAN" ?"active":""}`} onClick={() => setSelectedPlan("BASIC_PLAN")}>
                        <div className="plan-card-title">
                            Basic 플랜
                        </div>
                        <div className="plan-card-subtitle">
                            Subtitle
                        </div>
                        <div className="plan-card-function-wrap">
                            <div className="plan-card-function-line">
                                <div className="plan-card-check-icon" />
                                <div className="plan-card-function">이용 가능한 기능 1</div>
                            </div>
                            <div className="plan-card-function-line">
                                <div className="plan-card-check-icon" />
                                <div className="plan-card-function">이용 가능한 기능 2</div>
                            </div>
                            <div className="plan-card-function-line">
                                <div className="plan-card-check-icon" />
                                <div className="plan-card-function">이용 가능한 기능 3</div>
                            </div>
                            <div className="plan-card-function-line">
                                <div className="plan-card-check-icon" />
                                <div className="plan-card-function">이용 가능한 기능 4</div>
                            </div>
                        </div>
                    </div>
                    <div className={`plan-card ${selectedPlan == "PRO_PLAN" ?"active":""}`} onClick={() => setSelectedPlan("PRO_PLAN")}>
                        <div className="plan-card-title">
                            Pro 플랜
                        </div>
                        <div className="plan-card-subtitle">
                            Subtitle
                        </div>
                        <div className="plan-card-function-wrap">
                            <div className="plan-card-function-line">
                                <div className="plan-card-check-icon" />
                                <div className="plan-card-function">이용 가능한 기능 1</div>
                            </div>
                            <div className="plan-card-function-line">
                                <div className="plan-card-check-icon" />
                                <div className="plan-card-function">이용 가능한 기능 2</div>
                            </div>
                            <div className="plan-card-function-line">
                                <div className="plan-card-check-icon" />
                                <div className="plan-card-function">이용 가능한 기능 3</div>
                            </div>
                            <div className="plan-card-function-line">
                                <div className="plan-card-check-icon" />
                                <div className="plan-card-function">이용 가능한 기능 4</div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="change-email-btns">
                <div className="change-email-btn" onClick={()=>closeModal(CREATE_FILE_KEY)}>취소</div>
                <div className={`change-email-btn-change active`} onClick={clickChangeBtn}>업그레이드 신청 접수</div>
            </div>
        </div>
    );
}

export default ChangePlan;
