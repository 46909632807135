import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import './TextEditor.css';
import ContentEditable from 'react-contenteditable';
import { usePlainContentStore, useIdentifierListStore } from '../store.js';
import debounce from 'lodash/debounce';
import { postAItext } from '../api/apis.js';
import { getPatentDetail } from '../api/apis.js';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const TextEditor = forwardRef((props, ref) => {
    const { command } = props;
    const { setPlainContents, drawings } = usePlainContentStore();
    const [content, setContent] = useState(null);  // HTML content 상태
    const { setIdentifiers, setStandardIdentifiers, setClaims, setImages } = useIdentifierListStore();
    const [scale, setScale] = useState(1); // 확대/축소 비율 상태
    const [transformOrigin, setTransformOrigin] = useState("center top"); // 기본 중심점
    const editorRef = useRef(null);

    const query = useQuery();
    const patentID = query.get('id');

    const debouncedUpdatePlainText = useCallback(
        debounce((formattedText) => {
            updatePlainText(formattedText);
        }, 2000), // 2s 딜레이 적용
        []
    );

    const handleChange = (evt) => {
        const rawText = evt.target.value;
        const formattedText = applyFormatting(rawText);
        setContent(formattedText);
        debouncedUpdatePlainText(formattedText); // 디바운스된 함수 호출
    };

    useEffect(() => {
        const fetchDataAndInsertContent = async () => {
            if (editorRef.current && setContent && content === null) {
                try {
                    const data = await getPatentDetail(patentID); // 데이터 가져오기
                    const contentsData = data?.aiContent || " ";
                    
                    editorRef.current.focus();
                    document.execCommand('insertText', false, "___\n"+ contentsData || " ");
                } catch (error) {
                    console.error("Error fetching or inserting content:", error);
                }
            }
        };
    
        fetchDataAndInsertContent();
    }, [editorRef, setContent, content, patentID]);

    // 텍스트를 분석하고 포맷을 적용하는 함수
    const applyFormatting = (text) => {
        text = text.replace(/___/g, '<div class="none"></div>');
        text = text.replace(/&lt;b&gt;(.*?)&lt;\/b&gt;/g, '<b>$1</b>');
        text = text.replace(/&lt;i&gt;(.*?)&lt;\/i&gt;/g, '<i>$1</i>');
        text = text.replace(/&lt;u&gt;(.*?)&lt;\/u&gt;/g, '<u>$1</u>');
        text = text.replace(/&lt;strike&gt;(.*?)&lt;\/strike&gt;/g, '<strike>$1</strike>');
        text = text.replace(/&lt;sup&gt;(.*?)&lt;\/sup&gt;/g, '<sup>$1</sup>');
        text = text.replace(/&lt;sub&gt;(.*?)&lt;\/sub&gt;/g, '<sub>$1</sub>');
        text = text.replace(/@@@/g, '<div class="page-break" contenteditable="false"></div>');
        text = text.replace(/\?\?(.*?)\?\?(.*?)\?\?\1\?\?/g, (match, num, content) => {
            return `<span class="claim start" contenteditable="false">${num}</span>${content}<span class="claim end" contenteditable="false">${num}</span>`;
        });
        text = text.replace(/\?\?(.*?)\?\?/g, '<span class="claim" contenteditable="false">$1</span>');
        text = text.replace(/\*\*(\d+)\*\*/g, (match, num) => {
            const index = parseInt(num, 10);
            if (index >= 0 && index < drawings.length) {
                return `<img class="img-${num}" src="${drawings[index].url}" >`;
            } else{return ""}})
        text = text.replace(/!\*(.*?)\*!/g, `<img src="$1" >`)
        text = text.replace(/'''(.*?)'''/g, (match, p1) => {
            const paddingClass = /^\d/.test(p1.trim())
                ? 'padding-12'
                : /^\(/.test(p1.trim())
                ? 'padding-24'
                : '';
            return `<div class="std-id index ${paddingClass}" contenteditable="false">${p1}</div>`;
        });
        text = text.replace(/!【(.*?)】!/g, (match, p1) => {
            const alignClass = /^(발명의 설명|청구범위|요약서|도면)$/.test(p1.trim())
                ? 'align-center'
                : ""
            return `<div class="std-id ${alignClass}">【${p1}】</div>`;
        });
        text = text.replace(/##(.*?)##/g, '<div class="anchor" contenteditable="false"><div class="sTag" contenteditable="false">$1</div></div>');
        let citationCounter = 1;

        text = text.replace(/\^\^(.*?)\^\^/g, (match, link) => {
            // 출처 번호 및 툴팁 데이터 속성 추가
            const quoteHTML = `<a class="quote" href="${link}" target="_blank" data-link="${link}" contenteditable="false" data-title="[${citationCounter}]" data-content="${link}">${citationCounter++}</a>`;
            return quoteHTML;
        });
        text = text.replace(/\/\/\/(.*?)\/\/\//g, '<div class="core">$1</div>');
        return text;
    };

    const handlePaste = (evt) => {
        evt.preventDefault();
        let text = evt.clipboardData.getData('text/plain');
        text = text.replace(/\r\n|\r/g, '\n');
        document.execCommand('insertText', false, text);
    };

    const removeClassFromCurrentElement = () => {
        const selection = window.getSelection();
        if (!selection.rangeCount) return;
    
        const range = selection.getRangeAt(0);
        const parentElement = range.commonAncestorContainer.nodeType === 1 
            ? range.commonAncestorContainer 
            : range.commonAncestorContainer.parentElement;
        // console.log(parentElement)
        if (parentElement && parentElement.classList.length) {
            // console.log(parentElement.classList.length);
    
            // 제거할 클래스 리스트
            const classesToRemove = ["std-id", "align-center", "index", "anchor", "quote", "page-break", "sTag", "claim"];
    
            // 기존 클래스를 복사해 특정 클래스 제거
            const clonedElement = parentElement.cloneNode(true);
            classesToRemove.forEach(className => clonedElement.classList.remove(className));
    
            // 기존 요소를 지우고 수정된 클론을 삽입
            parentElement.replaceWith(clonedElement);
        }
    };
    
    const isStart = () => {
        const selection = window.getSelection();
        if (!selection.rangeCount) return false;
    
        const range = selection.getRangeAt(0);
        const startContainer = range.startContainer;
    
        // 커서가 문서의 시작 부분인지를 확인
        if (startContainer.nodeType === 3) { // 텍스트 노드일 경우
            return range.startOffset === 0; // 커서가 텍스트의 맨 앞에 있는지 확인
        }
    
        // 텍스트 노드가 아닐 경우, 부모 노드에서 확인
        return startContainer === startContainer.ownerDocument.body;
    };

    const verifyCaret = () => {
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const nextSibling = range.startContainer.nextSibling;
        const previousSibling = range.startContainer.previousSibling;
        const isAtStart = range.startOffset === 0;
        const isAtEnd = 
            range.startContainer.nodeType === Node.TEXT_NODE && // 텍스트 노드인지 확인
            range.startOffset === range.startContainer.textContent.length;
        const parentEl = selection.getRangeAt(0).startContainer.parentElement;
        const siblings = parentEl.parentNode.children; // 요소 노드만 포함
        const index = Array.prototype.indexOf.call(siblings, parentEl);

        if (isAtEnd && (nextSibling?.classList?.contains("quote") | nextSibling?.classList?.contains("claim"))) {
            const newRange = document.createRange();
            newRange.setStartAfter(nextSibling); // <a> 태그 바로 뒤로 커서 설정
            newRange.collapse(true); // 시작 위치와 끝 위치를 동일하게 설정

            // Selection 갱신
            selection.removeAllRanges();
            selection.addRange(newRange);
            verifyCaret()
        }
    }

    /// 이 함수는 왼쪽으로 이동 했을때 커서가 사라지는 현상을 없애기 위해서 존재함
    const verifyLeft = () => {
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const isAtStart = range.startOffset === 0;
        if (!isAtStart && range.startOffset === range.startContainer.childNodes?.length) {
            const newRange = document.createRange();
            newRange.setStartBefore(range.startContainer.childNodes[range.startContainer.childNodes.length - 1]); // 바로 뒤로 커서 설정
            newRange.collapse(true); // 시작 위치와 끝 위치를 동일하게 설정

            // Selection 갱신
            selection.removeAllRanges();
            selection.addRange(newRange);
        }
    }

    const conserveAnchor = () => {
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const nextSibling = range.startContainer.nextSibling;
        const previousSibling = range.startContainer.previousSibling;
        const isAtStart = range.startOffset === 0;
        const isAtEnd = 
            range.startContainer.nodeType === Node.TEXT_NODE && // 텍스트 노드인지 확인
            range.startOffset === range.startContainer.textContent.length;
        const parentEl = selection.getRangeAt(0).startContainer.parentElement;
        const siblings = parentEl.parentNode.children; // 요소 노드만 포함
        const index = Array.prototype.indexOf.call(siblings, parentEl);
        if (range.startOffset == 0 && parentEl && parentEl.parentNode) {
            // 부모 노드의 모든 자식 노드를 가져옵니다.
            const siblings = parentEl.parentNode.childNodes;
        
            // `parentEl`의 인덱스를 계산합니다.
            const index = Array.prototype.indexOf.call(siblings, parentEl);
            if(siblings[index-1]?.textContent?.trim() == ""){
                const targetNode = siblings[index - 2];
                if (targetNode?.classList?.contains("anchor")) {
                    const range = document.createRange();
                    const selection = window.getSelection();
            
                    // "anchor" 요소의 부모에서 해당 노드 앞에 캐럿을 설정
                    range.setStartBefore(targetNode); // 요소 앞에 캐럿 배치
                    range.collapse(true); // 시작점과 끝점이 동일한 위치로 설정
            
                    selection.removeAllRanges(); // 기존 선택 영역 제거
                    selection.addRange(range); // 새 선택 영역 추가
                }
            } else {
                const targetNode = siblings[index - 1];
                if (targetNode?.classList?.contains("anchor")) {
                    const range = document.createRange();
                    const selection = window.getSelection();
            
                    range.setStartBefore(targetNode);
                    range.collapse(true);
            
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
            }
        }
        if((range.startOffset === 0 | range.startOffset === 1) && previousSibling?.classList?.contains("anchor")) {
            const newRange = document.createRange();
            newRange.setStartBefore(previousSibling); // <a> 태그 바로 뒤로 커서 설정
            newRange.collapse(true); // 시작 위치와 끝 위치를 동일하게 설정

            // Selection 갱신
            selection.removeAllRanges();
            selection.addRange(newRange);
        }
    }
    
    const handleKeyDown = (e) => {
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        // console.log(range)
        // console.log("startContainer")
        // console.log(range.startContainer)
        // console.log("startContainer.parentElement")
        // console.log(range.startContainer.parentElement)
        // console.log("startContainer.nextSibling")
        // console.log(range.startContainer.nextSibling)
        // console.log(range.startContainer.previousSibling)
        const nextSibling = range.startContainer.nextSibling;
        const previousSibling = range.startContainer.previousSibling;
        const isAtStart = range.startOffset === 0;
        const isAtEnd = 
            range.startContainer.nodeType === Node.TEXT_NODE && // 텍스트 노드인지 확인
            range.startOffset === range.startContainer.textContent.length;

        // console.log(`캐럿이 마지막에 있음: ${isAtEnd}`);
        const parentEl = selection.getRangeAt(0).startContainer.parentElement;
        const siblings = parentEl.parentNode.children; // 요소 노드만 포함
        const index = Array.prototype.indexOf.call(siblings, parentEl);
        // console.log(siblings[index - 1])
        // console.log(`parentEl은 부모의 ${index}번째 자식 요소입니다.`);
        /// sTag 안으로 들어간 경우 탈출
        if(parentEl.getAttribute("contenteditable")==="false"){
            const newRange = document.createRange();
            newRange.setStartBefore(parentEl.parentElement);
            newRange.collapse(true);

            // Selection 갱신
            selection.removeAllRanges();
            selection.addRange(newRange);
        }
        
        if (e.key === 'Enter') {
            conserveAnchor()
            verifyCaret()
            
            setTimeout(() => {
                removeClassFromCurrentElement();
            }, 10);
        } else if(isAtStart && previousSibling?.classList?.contains("claim") && e.key !== "ArrowRight"){
            const newRange = document.createRange();
            newRange.setStartBefore(previousSibling);
            newRange.collapse(true);

            // Selection 갱신
            selection.removeAllRanges();
            selection.addRange(newRange);
        }
        if (e.key === 'Backspace') {
            conserveAnchor()
        }
        if (e.key === 'Delete') {
            if (isAtEnd && (nextSibling?.classList?.contains("claim"))) {
                const newRange = document.createRange();
                newRange.setStartAfter(nextSibling); // <a> 태그 바로 뒤로 커서 설정
                newRange.collapse(true); // 시작 위치와 끝 위치를 동일하게 설정
    
                // Selection 갱신
                selection.removeAllRanges();
                selection.addRange(newRange);
            }
            if (isAtStart && range.startContainer?.childNodes[0]?.classList?.contains("anchor")){
                const anchor = range.startContainer.childNodes[0]
                const newRange = document.createRange();
                newRange.setStartAfter(anchor); // <a> 태그 바로 뒤로 커서 설정
                newRange.collapse(true); // 시작 위치와 끝 위치를 동일하게 설정
    
                // Selection 갱신
                selection.removeAllRanges();
                selection.addRange(newRange);
            }
            if(range.startOffset === range.startContainer.length && nextSibling?.classList?.contains("anchor")) {
                const newRange = document.createRange();
                newRange.setStartAfter(nextSibling); // <a> 태그 바로 뒤로 커서 설정
                newRange.collapse(true); // 시작 위치와 끝 위치를 동일하게 설정
    
                // Selection 갱신
                selection.removeAllRanges();
                selection.addRange(newRange);
                verifyCaret()
            }
        }
        if (e.key === 'ArrowRight') {
            verifyCaret()
            if (isAtStart && range.startContainer?.childNodes[0]?.classList?.contains("anchor")){
                const anchor = range.startContainer.childNodes[0]
                const newRange = document.createRange();
                newRange.setStartAfter(anchor); // <a> 태그 바로 뒤로 커서 설정
                newRange.collapse(true); // 시작 위치와 끝 위치를 동일하게 설정
    
                // Selection 갱신
                selection.removeAllRanges();
                selection.addRange(newRange);
            }
            if(range.startOffset === range.startContainer.length && nextSibling?.classList?.contains("anchor")) {
                const newRange = document.createRange();
                newRange.setStartAfter(nextSibling); // <a> 태그 바로 뒤로 커서 설정
                newRange.collapse(true); // 시작 위치와 끝 위치를 동일하게 설정
    
                // Selection 갱신
                selection.removeAllRanges();
                selection.addRange(newRange);
                verifyCaret()
            }
        }
        if (e.key === 'ArrowLeft') {
            conserveAnchor()
            setTimeout(() => {
                verifyLeft()
            }, 10);
        }
    };

    const updatePlainText = async (htmlContent) => {
        // HTML을 DOM 파서로 변환
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');

        // 재귀적으로 하위 요소부터 텍스트를 변환하는 함수
        const parseNode = (node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                return node.nodeValue;  // 텍스트 노드인 경우 텍스트 그대로 반환
            }
            
            let text = '';
            node.childNodes.forEach((child) => {
                text += parseNode(child);  // 하위 노드를 재귀적으로 호출하여 텍스트 얻기
            });

            if (node.nodeType === Node.ELEMENT_NODE) {
                if (node.nodeName === 'BR') {
                    return '';
                }
                if (['B', 'I', 'U', 'STRIKE', 'SUP', 'SUB'].includes(node.nodeName)) {
                    return `<${node.nodeName.toLowerCase()}>${text}</${node.nodeName.toLowerCase()}>`;
                }
                const className = node.getAttribute('class');
                // console.log(node.childNodes.length)
                // console.log(node.childNodes)
                if (className && className.includes('claim')) {
                    return `??${text}??`;
                } else if (className && className.includes('index')) {
                    return `'''${text}'''`;
                } else if (className && className.includes('none')) {
                    return ``;
                } else if (className && className.includes('sTag')) {
                    return `##${text}##`;
                } else if (className && className.includes('std-id') && text.startsWith("【") && text.endsWith("】") ) {
                    return `!${text}!`;
                } else if (className && className.includes('anchor')) {
                    return `${text}`;
                } else if (className && className.includes('quote')) {
                    const dataLink = node.getAttribute('data-link');
                    return `^^${dataLink}^^`;
                } else if (className && className.includes('core')) {
                    return `///${text}///`;
                } else if (className && className.includes('page-break')) {
                    return "@@@";
                } else if (className && node.nodeName === 'IMG') {
                    const match = className.match(/img-(\d+)/); // 숫자 추출 정규식
                    console.log(match)
                    return `**${match[1]}**`;
                } else if (node.nodeName === 'IMG') {
                    const source = node.getAttribute('src');
                    return `!*${source}*!`;
                } else if (node.nodeName === 'DIV') {
                    return `${text}\n`;
                } else if (node.nodeName === 'PRE') {
                    return `${text}\n`;
                }
            }
            return text;
        };

        const parsedText = parseNode(doc.body);
        let plainText = parsedText;

        if (plainText.endsWith("\n")) {
            plainText = plainText.slice(0, -1);
        }
        setPlainContents(plainText);

        postAItext(patentID, plainText)
          .then(() => {
          })
          .catch((error) => {
            console.error("Error saving plainText:", error);
          });

        console.log("updated")
    };

    useEffect(() => {
        if (command?.cmd === 'insertIdentifier' && editorRef.current) {
            document.execCommand('insertHTML', false, `<div class="std-id">【${command?.arg}】</div>`);
        } else if (command?.cmd === 'insertPTag' && editorRef.current) {
            document.execCommand('insertHTML', false, '<div class="ptag" contenteditable="false">test 문단태그입니다.</div>');
        } else if (command?.cmd === 'insertStandardIdentifier' && editorRef.current) {
            if (/^(발명의 설명|청구범위|요약서|도면)$/.test(command?.arg)) {
                document.execCommand('insertHTML', false, `<div class="std-id align-center">【${command?.arg}】</div>`);
            } else {
                document.execCommand('insertHTML', false, `<div class="std-id">【${command?.arg}】</div>`);
            }
        } else if (command?.cmd === 'insertImage' && editorRef.current) {
            document.execCommand('insertHTML', false, `<img src="${command?.arg}"></img>`);
        } else if (command && editorRef.current) {
            document.execCommand(command.cmd, false, command.arg);
        }
    }, [command, editorRef]);

    useEffect(() => {
        const extractIdentifiers = () => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(content, 'text/html');

            const standardIdentifierElements = doc.querySelectorAll('.std-id');
            const standardIdentifierTexts = Array.from(standardIdentifierElements).map(el => el.innerText);

            setStandardIdentifiers(standardIdentifierTexts);
        };

        extractIdentifiers();

        const extractClaims = () => {
            const claimRegex = /<span class="claim start" contenteditable="false">(\d+)<\/span>(.*?)<span class="claim end" contenteditable="false">\1<\/span>/g;
            const claims = [];
            let match;

            while ((match = claimRegex.exec(content)) !== null) {
                const claimNumber = parseInt(match[1], 10); // claim 번호
                let claimContent = match[2].trim(); // claim 내용
                claimContent = claimContent.replace(/<\/?[^>]+(>|$)/g, "");
                claims.push({ claimNumber, content: claimContent });
            }

            setClaims(claims);
        };

        extractClaims();
    }, [content]);

    const handleWheel = (event) => {
        if (event.ctrlKey) {
            // Ctrl 키를 눌렀을 때만 확대/축소
            event.preventDefault(); // 기본 스크롤 방지
    
            let newScale = scale + (event.deltaY > 0 ? -0.2 : 0.2); // 확대/축소 비율 계산
            newScale = Math.min(Math.max(newScale, 0.2), 1.8); // 최소 0.2배, 최대 1.8배로 제한
            setScale(newScale);
    
            // 확대/축소 기준점 계산
            if (ref.current) {
                const container = ref.current;
                const scrollTop = container.scrollTop;
                const originY = scrollTop + container.clientHeight * 0 / 2;
    
                setTransformOrigin(`center ${originY}px`);
            }
        }
    };

    useEffect(() => {
        const editorContainer = ref.current;

        if (editorContainer) {
            editorContainer.addEventListener('wheel', handleWheel, { passive: false }); // wheel 이벤트 리스너 추가
        }
        return () => {
            if (editorContainer) {
                editorContainer.removeEventListener('wheel', handleWheel); // 컴포넌트 언마운트 시 리스너 제거
            }
        };
    }, [scale]);

    const handleSliderChange = (event) => {
        const newScale = parseFloat(event.target.value); // 슬라이더 값을 읽어와 반영
        setScale(newScale);
    };

    const clickZoomPlus = () => {
        const newScale = Math.min(Math.max(scale + 0.05, 0.2), 1.8); // 최소 0.5배, 최대 2배로 제한
        setScale(newScale);
    }

    const clickZoomMinus = () => {
        const newScale = Math.min(Math.max(scale - 0.05, 0.2), 1.8); // 최소 0.5배, 최대 2배로 제한
        setScale(newScale);
    }

    return (
        <div className="editor-wrap">
            <div className="editor-container" ref={ref} >
                <ContentEditable
                    innerRef={editorRef}
                    className="text-editor"
                    tagName="div"
                    html={content}
                    onChange={handleChange}
                    onPaste={handlePaste}
                    onKeyDown={handleKeyDown}
                    style={{ transform: `scale(${scale})`, transformOrigin: transformOrigin }}
                />
            </div>
            <div className="editor-bottom">
                <div className="editor-bottom-left"></div>
                <div className="editor-bottom-right">
                    <div className="zoom-scaler-box">
                        <img onClick={clickZoomMinus} src="/editor/minus.svg"/>
                        <div className="slider-container">
                            <input
                                type="range"
                                min="0.2"
                                max="1.8"
                                step="0.05"
                                value={scale}
                                onChange={handleSliderChange}
                                className="scale-slider"
                            />
                        </div>
                        <img onClick={clickZoomPlus} src="/editor/plus.svg"/>
                    </div>
                    <div className="current-zoom-percent">
                        {Math.round(scale * 100)}%
                    </div>
                </div>
            </div>
        </div>
    );
})

export default TextEditor;
