import React from 'react';
import './ToolbarHome.css'

function ToolbarHelp() {

    const handleFeedback = () => {
        alert(`피드백 및 문의사항은 아래의 경로로 문의 주시면 감사드리겠습니다.

고객센터: 010-4296-2559
문의메일: contact@drift-patent.com`)
    }

    return (
        <div className="toolbar toolbar-tools">
            <div className="toolbar-download" onClick={handleFeedback} >
                <div className="toolbar-image-icon big-icon">
                    <img src="/toolbar/feedback-icon.svg" />
                </div>
                <div className="toolbar-feedback-title">피드백 <br />보내기</div>
            </div>
        </div>
    );
}

export default ToolbarHelp;