import { useMemo, useRef } from 'react';
import './FileUpload.css';
import * as UTIF from "utif";

const FileUpload = ({ files, setFiles }) => {
  const fileInputRef = useRef(null);

  const handleChangeFile = async (event) => {
    const fileNameSet = new Set(files.map((file) => file.name));
    const duplicated = Array.from(event.target.files).some((newFile) =>
      fileNameSet.has(newFile.name)
    );

    if (duplicated) {
      return alert("동일한 이름의 파일이 이미 존재합니다.");
    }

    const maxSize = 10 * 1024 * 1024;
    const overSized = Array.from(event.target.files).some(
      (newFile) => newFile.size > maxSize
    );

    if (overSized) {
      return alert("파일 크기가 너무 큽니다. 10MB 이하의 이미지를 선택해주세요.");
    }

    const newFiles = [];
    const tiffPromises = [];

    Array.from(event.target.files).forEach((file) => {
      if (file.type === "image/tiff" || file.name.endsWith(".tiff")) {
        // TIFF 파일을 변환하는 Promise
        tiffPromises.push(
          new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              const arrayBuffer = e.target.result;
              const tiffImages = UTIF.decode(arrayBuffer);
              const tiffImage = tiffImages[0];
              UTIF.decodeImage(arrayBuffer, tiffImage);
              const rgba = UTIF.toRGBA8(tiffImage);

              // Canvas에 그리기
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
              canvas.width = tiffImage.width;
              canvas.height = tiffImage.height;

              const imageData = ctx.createImageData(
                tiffImage.width,
                tiffImage.height
              );
              imageData.data.set(rgba);
              ctx.putImageData(imageData, 0, 0);

              // JPG 변환
              canvas.toBlob(
                (blob) => {
                  const jpgFile = new File(
                    [blob],
                    file.name.replace(/\.tiff?$/i, ".jpg"),
                    { type: "image/jpeg" }
                  );
                  resolve(jpgFile);
                },
                "image/jpeg",
                0.8
              );
            };
            reader.readAsArrayBuffer(file);
          })
        );
      } else {
        // 일반 파일은 바로 추가
        newFiles.push(file);
      }
    });

    const tiffFiles = await Promise.all(tiffPromises);
    setFiles([...files, ...newFiles, ...tiffFiles]);

    // 파일 선택 후 input 값 리셋
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const fileNames = useMemo(() => {
    return Array.from(files).map((file) => file.name);
  }, [files]);

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const fileNameSet = new Set(files.map((file) => file.name));
    const duplicated = Array.from(event.dataTransfer.files).some((newFile) =>
      fileNameSet.has(newFile.name)
    );

    if (duplicated) {
      return alert('동일한 이름의 파일이 이미 존재합니다.');
    }

    const maxSize = 10 * 1024 * 1024;

    const overSized = Array.from(event.dataTransfer.files).some((newFile) =>{
      if (newFile.size > maxSize) {
        return true;
      }
    }
    );

    if (overSized) {
      return alert("파일 크기가 너무 큽니다. 10MB 이하의 이미지를 선택해주세요.");
    }

    const newFiles = Array.from(event.dataTransfer.files);
    setFiles((prev) => [...prev, ...newFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div
      className="file-upload-wrapper"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {fileNames.length === 0 ? (
        <label className="file-upload-empty-file-container">
          <img alt="add-icon" src="/toolbar/add-file.svg" />
          <div className="file-upload-add-file-text">파일 첨부</div>
          <input
            ref={fileInputRef}
            type="file"
            multiple
            onChange={handleChangeFile}
            style={{ display: 'none' }}
          />
        </label>
      ) : (
        <div className="file-upload-container">
          <div className="file-list">
            {fileNames.map((file, index) => (
              <div className="file-item" key={index}>
                <span>{file}</span>
                <button
                  className="remove-button"
                  onClick={() => handleRemoveFile(file)}
                >
                  <img alt="delete-icon" src="/toolbar/delete-file.svg" />
                </button>
              </div>
            ))}
            <label className="add-file-button">
              + 파일 추가
              <input
                ref={fileInputRef}
                type="file"
                multiple
                onChange={handleChangeFile}
                style={{ display: 'none' }}
              />
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
