import React, {useContext} from 'react';
import './ToolbarHome.css';
import { ModalContext } from './ModalProvider.js';
import { CREATE_FILE_KEY } from '../constants/modal.js';
import DrawingSetting from './DrawingSetting.js'

function ToolbarTools({url, patentID}) {
    const { openModal } = useContext(ModalContext);

    return (
        <div className="toolbar toolbar-tools">
            <div className="toolbar-download" onClick={() => window.open(url, '_blank', 'width=800,height=600')}>
                <div className="toolbar-image-icon big-icon">
                    <img src="/toolbar/drawing-icon.svg" />
                </div>
                <div className="toolbar-paste-title">도면</div>
            </div>
            <div className="toolbar-download" onClick={() => openModal(CREATE_FILE_KEY, <DrawingSetting fileId={patentID} />, () => false)} >
                <div className="toolbar-image-icon big-icon">
                    <img src="/toolbar/drawing-modify-icon.svg" />
                </div>
                <div className="toolbar-paste-title">도면 수정</div>
            </div>
        </div>
    );
}

export default ToolbarTools;