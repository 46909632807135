import { useContext, useEffect, useState } from 'react';
import Tab from '../components/Tab';
import './InputFileInfo.css';
import { useNavigate } from 'react-router-dom';
import SelectBox from '../components/SelectBox';
import FileUpload from '../components/FileUpload';
import DrawingTableForm from '../components/DrawingTableForm';
import {
  getRequestPatentTemp,
  patchRequestPatentsDetail,
  saveTemporaryClaimAndDrawings,
  postRequestPatentsDetail,
} from '../api/apis';
import { ModalContext } from '../components/ModalProvider';
import { CLOSE_DIALOG_UPDATE_FILE_KEY } from '../constants/modal';
import Dialog from '../components/Dialog';
import { CircularProgress } from '@mui/material';
import Confirmation from '../components/Confirmation';

const MENUS = [
  { label: '청구항', value: 'CLAIM' },
  { label: '발명내용', value: 'INVENTION' },
  { label: '도면', value: 'DRAWING' },
];

const LABELS = MENUS.map((menu) => menu.label);

const INVENTION_TYPES = ['기계', '전자', '화학', '바이오'];

const DEFAULT_INVENTION_TYPE = INVENTION_TYPES[0];

const DEFAULT_DRAWING_CONTENT_LENGTH = 4;

const urlToFile = async (url, fileName) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    const file = new File([blob], fileName, { type: blob.type });

    return file;
  } catch (error) {
    console.error('URL로부터 파일을 생성하는 중 오류 발생:', error);
    return null;
  }
};

const createNewContent = () => {
  const contents = Array(DEFAULT_DRAWING_CONTENT_LENGTH)
    .fill(0)
    .map(() => ({
      composition: '',
      sign: '',
    }));

  return [...contents];
};

function InputFileInfo({ fileId, patentId, title }) {
  const navigate = useNavigate();

  const [claim, setClaim] = useState('');
  const [inventionType, setInventionType] = useState(DEFAULT_INVENTION_TYPE);
  const [inventionContent, setInventionContent] = useState('');
  const [inventionFiles, setInventionFiles] = useState([]);
  const [drawingFiles, setDrawingFiles] = useState([]);
  const [drawingDescription, setDrawingDescription] = useState('');
  const [drawingContents, setDrawingContents] = useState([createNewContent()]);

  const { closeModal, openModal } = useContext(ModalContext);

  const [isPending, setIsPending] = useState(true);
  const [savedFormData, setSavedFormData] = useState();
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    // TODO: 파일 정보도 불러오기
    getRequestPatentTemp(fileId)
      .then((res) => {
        if (!res) return;
        const INVENTION_TYPES = {"MECHANICAL": "기계", "CHEMICAL": "화학", "ELECTRONICS": "전자", "BIO": "바이오"}
        let INVENTION_TYPE
        if (Object.keys(INVENTION_TYPES).includes(res.invention.type)) {
          INVENTION_TYPE = INVENTION_TYPES[res.invention.type]
        } else {
          INVENTION_TYPE = res.invention.type
        }

        setClaim(res.claim || '');
        setInventionType(INVENTION_TYPE || DEFAULT_INVENTION_TYPE);
        setInventionContent(res.invention.content || '');
        setDrawingDescription(res.drawingDescription || '');

        setInventionFiles([]);
        res.invention?.fileUrls?.forEach((info) => {
          // const splitted = url.split('/');
          // const filename = splitted[splitted.length - 1];
          // TODO: 파일 이름 정보 추가
          urlToFile(info.url).then((file) => {
            if (file) {
              const renamedFile = new File([file], info.name, {
                type: file.type,
                lastModified: file.lastModified,
              });
              setInventionFiles((prev) => [...prev, renamedFile]);
            }
          });
        });

        setDrawingFiles([]);
        res.drawingFiles?.forEach((info) => {
          // TODO: 파일 이름 정보 추가
          urlToFile(info.url).then((file) => {
            if (file) {
              const renamedFile = new File([file], info.name, {
                type: file.type,
                lastModified: file.lastModified,
              });
              setDrawingFiles((prev) => [...prev, renamedFile]);
            }
          });
        });

        if (res.drawingContents && res.drawingContents.length > 0) {
          setDrawingContents(res.drawingContents.map((item) => item.contents));
        } else {
          setDrawingContents([createNewContent()]);
        }

        setSavedFormData(structuredClone(res));
      })
      .finally(() => {
        setIsPending(false);
      });
  }, [fileId]);

  const validateCloseModal = () => {
    // TODO: 수정한 파일 데이터가 있는 경우 비교하도록 수정
    if (
      savedFormData.claim !== claim ||
      savedFormData.invention.content !== inventionContent ||
      savedFormData.drawingDescription !== drawingDescription
    ) {
      return false;
    }

    return true;
  };

  const handleCloseModal = () => {
    if (validateCloseModal()) {
      navigate(-1);
    } else {
      openModal(
        CLOSE_DIALOG_UPDATE_FILE_KEY,
        <Dialog
          title="경고"
          content={`현재까지 작성한 내용이 저장되지 않습니다. \n 정말 나가시겠습니까?`}
          confirmButtonText="나가기"
          onCancel={() => closeModal(CLOSE_DIALOG_UPDATE_FILE_KEY)}
          onConfirm={() => {
            closeModal(CLOSE_DIALOG_UPDATE_FILE_KEY);
            navigate(-1);
          }}
        />
      );
    }
  };

  const [tabIndex, setTabIndex] = useState(0);

  const [drawingInfoIndex, setDrawingInfoIndex] = useState(0);

  const getPatentDetail = () => {
    const formData = new FormData();

    formData.append('claim', claim);
    formData.append('invention.type', inventionType); // TODO: 타입 백엔드와 맞추기
    formData.append('invention.content', inventionContent);
    formData.append('drawingDescription', drawingDescription);

    inventionFiles.forEach((file, index) => {
      if (file instanceof File) {
        formData.append(`invention.files[${index}]`, file, file.name);
      }
    });

    drawingFiles.forEach((file, index) => {
      if (file instanceof File) {
        formData.append(`drawingFiles[${index}]`, file, file.name);
      }
    });

    let index = 0;
    drawingContents.forEach((contents, tableIndex) => {
      contents.forEach((content) => {
        formData.append(
          `drawingContents[${index}].drawingIndex`,
          tableIndex + 1
        );

        formData.append(
          `drawingContents[${index}].composition`,
          content.composition || ''
        );

        formData.append(`drawingContents[${index}].sign`, content.sign || '');

        index++;
      });
    });

    return formData;
  };

  const addDrawingContents = () => {
    setDrawingContents((prev) => [...prev, createNewContent()]);
    setDrawingInfoIndex(drawingContents.length)
  };

  const handleNext = () => {
    if (tabIndex < MENUS.length - 1) {
      setTabIndex((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    if (tabIndex > 0) {
      setTabIndex((prev) => prev - 1);
    } else {
      handleCloseModal();
    }
  };

  const handleChangeDrawingContents = (newContents) => {
    setDrawingContents((prev) => {
      const newInfos = prev.map((info, index) => {
        if (index === drawingInfoIndex) return structuredClone(newContents);

        return info;
      });

      return newInfos;
    });
  };

  const handleSave = async () => {
    if (!claim) return window.alert('필수 정보를 입력해주세요');

    let request = saveTemporaryClaimAndDrawings

    const formData = getPatentDetail();
    request(fileId, formData)
      .then(() => {
        openModal(
          'SAVE',
          <Confirmation
            title="알림"
            content="사건이 임시 저장되었습니다"
            onConfirm={() => {
              closeModal('SAVE');
              window.location.reload();
            }}
          />,
          () => false
        );
      })
      .catch(() => {
        openModal(
          'SAVE',
          <Confirmation
            title="알림"
            content={'사건이 정상적으로 저장되지 않았습니다'}
            onConfirm={() => {
              closeModal('SAVE');
            }}
          />,
          () => false
        );
      });
  };

  const handleSubmit = () => {
    if (!claim && claim.length <= 0) return;

    let request = postRequestPatentsDetail;

    // if (savedFormData && savedFormData.claim) {
    //   request = patchRequestPatentsDetail;
    // } else {
    //   request = postRequestPatentsDetail;
    // }

    const formData = getPatentDetail();
    request(fileId, formData).then(() => {
      setIsDone(true);
    })
    .catch(() => {
      openModal(
        'SAVE',
        <Confirmation
          title="알림"
          content={'사건이 정상적으로 생성되지 않았습니다'}
          onConfirm={() => {
            closeModal('SAVE');
          }}
        />,
        () => false
      );
    });;
  };

  if (isDone) {
    return (
      <div className="done-wrap">
        <div className="spinner-wrap">
          <CircularProgress />
        </div>
        <div className="done-title">초안을 생성하고 있습니다.</div>
        <div className="done-subtitle">초안 생성은 최대 60분 정도 소요됩니다.</div>
        <div className="done-contact">문의: contact@drift-patent.com</div>
        <div className="done-go-dashboard" onClick={()=>{navigate("/?menu=FileList")}}>대시보드로 돌아가기</div>
      </div>
    )
  }

  return (
    <div className="input-file-info-container">
      <div className="input-file-info-header">
        <div className="input-file-info-header-top">
          <div className="input-file-info-title">{`${patentId} ${title}`}</div>
          <div className="input-file-info-btn-container">
            <button className="input-file-info-btn" onClick={handleSave}>
              임시 저장
            </button>
            <button
              onClick={handleSubmit}
              className={`input-file-info-btn ${
                claim.length > 0 ? 'submit' : 'inactive'
              }`}
            >
              생성하기
            </button>
          </div>
        </div>
        <div>
          <Tab labels={LABELS} currentIndex={tabIndex} setIndex={setTabIndex} />
        </div>
      </div>
      <div className="input-file-info-contents">
        {(function () {
          if (isPending)
            return (
              <div
                classNam
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '500px',
                }}
              >
                <CircularProgress />
              </div>
            );

          // TODO: 컴포넌트로 분리
          return [
            <div className="content-wrap">
              <div className="content-main-left">
                <div className="content-title-wrap">
                  <div className="content-title">청구항</div>
                  <div className="required-option-label ">(필수)</div>
                </div>
              </div>
              <textarea
                className="patent-claim"
                placeholder="청구항을 입력해주세요."
                value={claim}
                onChange={(e) => setClaim(e.target.value)}
              />
            </div>,
            <div className="content-container">
              <div className="content-wrap">
                <div className="content-main-left">
                  <div className="content-title-wrap">
                    <div className="content-title">기술 분야</div>
                  </div>
                </div>
                <SelectBox
                  options={INVENTION_TYPES}
                  selectedOption={inventionType}
                  setSelectedOption={setInventionType}
                  width="290px"
                />
                <div style={{ height: '24px' }} />
                <div className="content-main-left">
                  <div className="content-title-wrap">
                    <div className="content-title">발명 내용</div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <FileUpload
                    files={inventionFiles}
                    setFiles={setInventionFiles}
                  />
                  <textarea
                    style={{ padding: '12px', height: '280px' }}
                    className="patent-claim"
                    placeholder="발명 내용을 입력해주세요."
                    value={inventionContent}
                    onChange={(e) => setInventionContent(e.target.value)}
                  />
                </div>
              </div>
            </div>,
            <div className="content-wrap">
              <div className="content-main-left">
                <div className="content-title-wrap">
                  <div className="content-title">도면</div>
                </div>
              </div>
              <FileUpload files={drawingFiles} setFiles={setDrawingFiles} />
              <div style={{ height: '24px' }} />
              <div className="content-main-left">
                <div className="content-title-wrap">
                  <div className="content-title">{'【도면의 간단한 설명】'}</div>
                </div>
              </div>
              <textarea
                style={{ padding: '12px', height: '280px' }}
                className="patent-claim"
                placeholder="도면 내용을 입력해주세요."
                value={drawingDescription}
                onChange={(e) => setDrawingDescription(e.target.value)}
              />
              <div style={{ height: '24px' }} />
              <div className="content-main-left">
                <div className="content-title-wrap">
                  <div className="content-title">도면 구성 및 부호</div>
                </div>
              </div>
              <div style={{ height: '4px' }} />
              <div style={{ display: 'flex' }}>
                <Tab
                  labels={drawingContents.map((_, index) => `도 ${index + 1}`)}
                  currentIndex={drawingInfoIndex}
                  setIndex={setDrawingInfoIndex}
                />
                <button
                  className="add-info-button"
                  onClick={addDrawingContents}
                >
                  <img
                    alt="add-icon"
                    src="/toolbar/add-file.svg"
                    style={{ width: '16px', height: '16px' }}
                  />
                </button>
              </div>
              <div style={{ height: '10px' }} />

              <DrawingTableForm
                contents={drawingContents[drawingInfoIndex] || []}
                setContents={handleChangeDrawingContents}
              />
            </div>,
          ][tabIndex];
        })()}
        <div
          className="input-file-info-btn-container"
          style={{ flexDirection: 'row-reverse', marginTop: '28px' }}
        >
          {tabIndex < MENUS.length - 1 && (
            <button className="input-file-info-btn" onClick={handleNext}>
              다음
            </button>
          )}
          <button className="input-file-info-btn back" onClick={handlePrev}>
            이전
          </button>
        </div>
      </div>
    </div>
  );
}

export default InputFileInfo;
