import { useContext, useState } from 'react';
import { ModalContext } from './components/ModalProvider.js';
import {
  CLOSE_DIALOG_CREATE_FILE_KEY,
  CREATE_FILE_KEY,
} from './constants/modal.js';
import Dialog from './components/Dialog.js';
import { patchRequestPatents } from './api/apis.js';
import './CreateNewFile.css';
import Confirmation from './components/Confirmation.js';

function ModifyFile({id, fileInfo}) {
  console.log(fileInfo)

  const [patentId, setPatentId] = useState(fileInfo.patentId);
  const [title, setTitle] = useState(fileInfo.title);
  const [client, setClient] = useState(fileInfo.client);
  const [receiptDate, setReceiptDate] = useState(fileInfo.start);
  const [dueDate, setDueDate] = useState(fileInfo.due);
  const [memo, setMemo] = useState(fileInfo.memo);

  const { closeModal, openModal } = useContext(ModalContext);

  const validateCloseModal = () => {
    if (patentId || title || client || receiptDate || dueDate || memo) {
      return false;
    }

    return true;
  };

  const handleCloseModal = () => {
    if (validateCloseModal()) {
      closeModal(CREATE_FILE_KEY);
    } else {
      openModal(
        CLOSE_DIALOG_CREATE_FILE_KEY,
        <Dialog
          title="경고"
          content={`현재까지 작성한 내용이 저장되지 않습니다. \n 정말 나가시겠습니까?`}
          confirmButtonText="나가기"
          onCancel={() => closeModal('KEY')}
          onConfirm={() => closeModal(CREATE_FILE_KEY)}
        />
      );
    }
  };

  const SaveAndGoHome = () => {
    if (!patentId || !title) {
      alert('사건 번호와 제목을 입력해주세요.');

      return;
    }

    const newPatent = {
      patentId,
      title,
      client,
      dueDate,
      receiptDate,
      memo,
    };

    patchRequestPatents(id, newPatent)
      .then(() => {
        openModal(
          CLOSE_DIALOG_CREATE_FILE_KEY,
          <Confirmation
            title="알림"
            content="사건 정보가 수정되었습니다"
            onConfirm={() => {
              closeModal(CREATE_FILE_KEY);
              window.location.reload();
            }}
          />,
          () => false
        );
      })
      .catch((err) => {
        console.error(err);
        alert('수정에 실패하였습니다.');
      });
  };

  return (
    <>
      <div className="">
        <main className="create-file-container">
          <div className="create-file-title">사건 정보 수정</div>
          <div className="content-column">
            <div className="content-wrap">
              <div className="content-title-wrap">
                <div className="content-title">사건 번호</div>
                <div className="required-option-label">(필수)</div>
              </div>
              <input
                className="content-input"
                placeholder="ex. 003234"
                value={patentId}
                onChange={(e) => setPatentId(e.target.value)}
              />
            </div>
            <div className="content-wrap">
              <div className="content-title-wrap">
                <div className="content-title">제목</div>
                <div className="required-option-label">(필수)</div>
              </div>
              <input
                className="content-input"
                placeholder="제목을 입력해주세요"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="content-wrap">
              <div className="content-title-wrap">
                <div className="content-title">클라이언트</div>
              </div>
              <input
                className="content-input"
                placeholder="클라이언트명을 입력해주세요"
                value={client}
                onChange={(e) => setClient(e.target.value)}
              />
            </div>
            <div className="content-wrap">
              <div className="content-title-wrap">
                <div className="content-title">사건 접수일</div>
                <div className="required-option-label">(필수)</div>
              </div>
              <input
                className="content-input"
                type="date"
                value={receiptDate}
                max={dueDate}
                onChange={(e) => setReceiptDate(e.target.value)}
              />
            </div>
            <div className="content-wrap">
              <div className="content-title-wrap">
                <div className="content-title">초안 송부 기한</div>
                <div className="required-option-label">(필수)</div>
              </div>
              <input
                className="content-input"
                min={receiptDate}
                type="date"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
              />
            </div>
            <div className="content-wrap">
              <div className="content-title-wrap">
                <div className="content-title">비고</div>
              </div>
              <textarea
                className="textarea-note"
                placeholder="내용을 입력해주세요."
                value={memo}
                onChange={(e) => setMemo(e.target.value)}
              />
            </div>
          </div>
          <div className="btns-wrap">
            <button className="save-btn" onClick={SaveAndGoHome}>
              저장하기
            </button>
            <button className="back-btn" onClick={handleCloseModal}>
              취소
            </button>
          </div>
        </main>
      </div>
    </>
  );
}

export default ModifyFile;
