import React, {useEffect} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import FileSelection from './FileSelection';
import CreateNewFile from './CreateNewFile';
import EditFile from './EditFile';
import './App.css';
import ContentEditor from './ContentEditor3';
import { ModalProvider } from './components/ModalProvider';
import { getUserInfo } from './api/apis.js';
import Terms from './Terms'
import Privacy from './Privacy'

const LogOut = () => {
    sessionStorage.setItem('SessionId', null);
    window.location.reload();
};

function App() {
  useEffect(() => {
  getUserInfo()
  .then((data) => {
      const formatDate = (isoDate) => {
          const dateObj = new Date(isoDate);
          return `${dateObj.getFullYear()}. ${dateObj.getMonth() + 1}. ${dateObj.getDate()}.`;
      };

      const calculateNextBillingDate = (isoDate) => {
          const dateObj = new Date(isoDate);
          dateObj.setDate(dateObj.getDate() + 1); // 하루 추가
          return formatDate(dateObj);
      };
  
      // 날짜 형식 변경
      const formattedData = {
          ...data,
          subscription: {
            ...data.subscription,
            startDate: formatDate(data.subscription.startDate),
            endDate: formatDate(data.subscription.endDate),
            nextBillingDate: calculateNextBillingDate(data.subscription.endDate),
          },
      };

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      console.log(formatDate(today))
      console.log(formatDate(data.subscription.endDate))

      if(formattedData.subscription.type == "FREE_TRIAL" &&  formatDate(today) > formattedData.subscription.endDate) {
          alert("무료 체험이 종료되어 더이상 서비스를 이용하실 수 없습니다.")
          LogOut()
      }
  })
  .catch((err) => {
      console.error(err);
  })
  .finally(() => {
  });
  },[])

  return (
    <Router>
      <div className="App">
        <ModalProvider>
          <Routes>
            <Route path="/" element={<FileSelection />} />
            <Route path="/new" element={<CreateNewFile />} />
            <Route path="/edit" element={<EditFile />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/contentEditor" element={<ContentEditor />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </ModalProvider>
      </div>
    </Router>
  );
}

export default App;
