import { logOut } from '../auth/auth';

const SERVER_URL = 'https://dev-api.drift-patent.com';

const fetcher = async (method, path, requestBody, isMultipart) => {
  const sessionId = sessionStorage.getItem('SessionId');

  try {
    const response = await fetch(`${SERVER_URL}${path}`, {
      method,
      headers: {
        ...(sessionId && { Authorization: sessionId }),
        ...(isMultipart || { 'Content-Type': 'application/json' }),
      },
      ...(requestBody && {
        body: isMultipart ? requestBody : JSON.stringify(requestBody),
      }),
    });

    if (response.ok && response.body && method === 'GET') {
      const responseData = await response.json();
      return responseData;
    }

    if (response.status === 401) {
      alert("세션 만료 혹은 중복 로그인으로 인해 재로그인 부탁드립니다.")
      logOut();
    }

    if (response.error || !response.ok) {
      throw new Error(response.error);
    }

    return response;
  } catch (error) {
    console.error('ERROR:', error.message);
    throw new Error(error.message);
  }
};

export const request = {
  GET: (path) => fetcher('GET', path),
  POST: (path, requestBody, isMultipart) =>
    fetcher('POST', path, requestBody, isMultipart),
  PATCH: (path, requestBody, isMultipart) =>
    fetcher('PATCH', path, requestBody, isMultipart),
  DELETE: (path, requestBody) => fetcher('DELETE', path, requestBody),
};
