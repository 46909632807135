import { useState, useContext } from 'react';
import './CancelPlan.css';
import { cancelPlanRequest } from '../api/apis.js';
import { ModalContext } from '../components/ModalProvider.js';
import { CREATE_FILE_KEY } from '../constants/modal.js';

function CancelPlan({id}) {
    const [reason, setReason] = useState("");
    const [isChanged, setIsChanged] = useState(false);

    const { closeModal } = useContext(ModalContext);

    const clickChangeBtn = () => {
        if(!reason) {
            return
        }
        
        cancelPlanRequest(id, reason)
        .then((data) => {
            setIsChanged(true);
        })
        .catch((err) => {
            console.error(err);
        })
        .finally(() => {
        });
    }

    if (isChanged) {
        return <div className="done-change-wrap">
            <div className="done-change-title">알림</div>
            <div className="done-change-content">문의 접수가 완료되었습니다.</div>
            <div className="done-change-ok" onClick={()=>{closeModal(CREATE_FILE_KEY); window.location.reload()}}>확인</div>
        </div>
    }
    return (
        <div className="change-email-container">
            <div className="change-email-contents">
                <div className="change-email-title">구독 해지 문의</div>
                <div className="change-email-subtitle">문의를 남겨주시면 영업일 기준 3일 이내로 연락드리겠습니다.</div>
                <div className="change-cancel-label">해지 사유</div>
                <textarea 
                    className="change-cancel-target"
                    placeholder="구독 해지 사유를 남겨주세요"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)} />
            </div>
            <div className="change-email-btns">
                <div className="change-email-btn" onClick={()=>closeModal(CREATE_FILE_KEY)}>취소</div>
                <div className={`change-email-btn-change ${reason?"active":""}`} onClick={clickChangeBtn}>문의 접수</div>
            </div>
        </div>
    );
}

export default CancelPlan;
